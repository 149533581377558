import {
  getManufacturerEqp,
  putManufacturerEqp,
  deleteManufacturerEqp,
  getManufacturerEqps,
  postManufacturerEqp,
  getManufacturerFactoryName,
} from '@/api/modules/manufacturer/equipments';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 설비 회사 설비 목록 조회
  async GET_MANUFACTURER_EQPS({ commit }, [page, pageSize, sortOrder, keyword]) {
    try {
      const { data } = await getManufacturerEqps(page, pageSize, sortOrder, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 설비 상세
  async GET_MANUFACTURER_EQP({ commit }, eqpId) {
    try {
      const { data } = await getManufacturerEqp(eqpId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 설비 회사 설비 등록
  async POST_MANUFACTURER_EQP({ commit }, eqp) {
    try {
      const { data } = await postManufacturerEqp(eqp);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 설비 회사 설비 수정
  async PUT_MANUFACTURER_EQP({ commit }, [eqpId, eqp]) {
    try {
      const { data } = await putManufacturerEqp(eqpId, eqp);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 설비 회사 설비 삭제
  async DELETE_MANUFACTURER_EQP({ commit }, eqpId) {
    try {
      const { data } = await deleteManufacturerEqp(eqpId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 도입회사명 목록 조회 (드롭다운)
  async GET_MANUFACTURER_FACTORY_NAME({ commit }) {
    try {
      const { data } = await getManufacturerFactoryName();
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
