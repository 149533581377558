import { checkSheetItemFiles } from '@/api';

function postCheckSheetItemFile(file) {
  return checkSheetItemFiles.post(``, file);
}

function deleteCheckSheetItemFile(fileId) {
  return checkSheetItemFiles.delete(`${fileId}`);
}

export { postCheckSheetItemFile, deleteCheckSheetItemFile };
