import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/view/login/Login.vue'),
      meta: { auth: false },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('@/view/resetPassword/ResetPassword.vue'),
      meta: { auth: false },
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfUse',
      component: () => import('@/view/termsOfUse/TermsOfUse.vue'),
      meta: { auth: false },
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('@/view/privacyPolicy/PrivacyPolicy.vue'),
      meta: { auth: false },
    },
    {
      path: '/role-selection',
      name: 'RoleSelection',
      component: () => import('@/view/roleSelection/RoleSelection.vue'),
      meta: { auth: true, hasMultiManagerRoles: true },
    },
    {
      path: '/users',
      name: 'UserList',
      component: () => import('@/view/users/userList/UserList.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'users' },
    },
    {
      path: '/users/register',
      name: 'UserRegister',
      component: () => import('@/view/users/userRegister/UserRegister.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'users' },
    },
    {
      path: '/users/:mId',
      name: 'UserDetail',
      component: () => import('@/view/users/userDetail/UserDetail.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'users' },
    },
    {
      path: '/equipment-groups',
      name: 'EquipmentGroupList',
      component: () => import('@/view/equipmentGroups/equipmentGroupList/EquipmentGroupList.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'infra' },
    },
    {
      path: '/equipment-groups/register',
      name: 'EquipmentGroupRegister',
      component: () => import('@/view/equipmentGroups/equipmentGroupRegister/EquipmentGroupRegister.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'infra' },
    },
    {
      path: '/equipment-groups/:egId',
      name: 'EquipmentGroupDetail',
      component: () => import('@/view/equipmentGroups/equipmentGroupDetail/EquipmentGroupDetail.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'infra' },
    },
    {
      path: '/equipments',
      name: 'EquipmentList',
      component: () => import('@/view/equipments/equipmentList/EquipmentList.vue'),
      meta: { auth: true, gnb: 'infra' },
    },
    {
      path: '/equipments/register',
      name: 'EquipmentRegister',
      component: () => import('@/view/equipments/equipmentRegister/EquipmentRegister.vue'),
      meta: { auth: true, gnb: 'infra' },
    },
    {
      path: '/equipments/:eId',
      name: 'EquipmentDetail',
      component: () => import('@/view/equipments/equipmentDetail/EquipmentDetail.vue'),
      meta: { auth: true, gnb: 'infra' },
    },
    {
      path: '/sensors',
      name: 'SensorList',
      component: () => import('@/view/sensors/sensorList/SensorList.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'infra' },
    },
    {
      path: '/sensors/register',
      name: 'SensorRegister',
      component: () => import('@/view/sensors/sensorRegister/SensorRegister.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'infra' },
    },
    {
      path: '/sensors/:sId',
      name: 'SensorDetail',
      component: () => import('@/view/sensors/sensorDetail/SensorDetail.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'infra' },
    },
    {
      path: '/equipment-monitoring',
      name: 'EquipmentMonitoring',
      component: () => import('@/view/equipmentMonitoring/EquipmentMonitoring.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipmentMonitoring' },
    },
    {
      path: '/check-sheet',
      name: 'CheckSheetList',
      component: () => import('@/view/checkSheet/CheckSheetList.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipments', lnb: 'check-sheet' },
      props: (route) => ({ query: route.query.eqp }),
    },
    {
      path: '/check-sheet/register',
      name: 'CheckSheetRegister',
      component: () => import('@/view/checkSheet/CheckSheetRegister.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipments', lnb: 'check-sheet' },
    },
    {
      path: '/check-sheet/register/:cId',
      name: 'CheckSheetEdit',
      component: () => import('@/view/checkSheet/CheckSheetRegister.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipments', lnb: 'check-sheet' },
    },
    {
      path: '/ar-contents',
      name: 'ArContentsList',
      component: () => import('@/view/arContents/arContentsList/ArContentsList.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipments', lnb: 'ar-contents' },
    },
    {
      path: '/ar-contents/:tId',
      name: 'ArContentsDetail',
      component: () => import('@/view/arContents/arContentsDetail/ArContentsDetail.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipments', lnb: 'ar-contents' },
    },
    {
      path: '/check-history-calendar',
      name: 'CheckHistoryCalendar',
      component: () => import('@/view/checkHistory/checkHistoryCalendar/CheckHistoryCalendar.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipments' },
    },
    {
      path: '/check-history-calendar/:eId',
      name: 'CheckHistoryCalendarDetail',
      component: () => import('@/view/checkHistory/checkHistoryCalendarDetail/CheckHistoryCalendarDetail.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'equipments' },
    },
    {
      path: '/factory-equipments',
      name: 'FactoryEquipmentList',
      component: () => import('@/view/factoryEquipments/factoryEquipmentList/FactoryEquipmentList.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'infra' },
    },
    {
      path: '/factory-equipments/:iqId',
      name: 'FactoryEquipmentDetail',
      component: () => import('@/view/factoryEquipments/factoryEquipmentDetail/FactoryEquipmentDetail.vue'),
      meta: { auth: true, isFactoryComp: true, gnb: 'infra' },
    },
    {
      path: '/call-history',
      name: 'CallHistory',
      component: () => import('@/view/callHistory/CallHistory.vue'),
      meta: { auth: true, isSupervisorOrFactory: true, gnb: 'remote' },
      query: { page: 1 },
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/view/notFound/NotFound.vue'),
      meta: { auth: false },
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: () => import('@/view/forbidden/Forbidden.vue'),
      meta: { auth: false },
    },
    {
      path: '/openSourceLicense/android',
      name: 'OpenSourceLicenseAndroid',
      component: () => import('@/view/openSourceLicense/OpenSourceLicense.vue'),
      meta: { auth: false },
    },
    {
      path: '/openSourceLicense/iOS',
      name: 'OpenSourceLicenseiOS',
      component: () => import('@/view/openSourceLicense/OpenSourceLicense.vue'),
      meta: { auth: false },
    },
    {
      path: '/openSourceLicense/web',
      name: 'OpenSourceLicenseWeb',
      component: () => import('@/view/openSourceLicense/OpenSourceLicense.vue'),
      meta: { auth: false },
    },
    {
      path: '/qr-code',
      name: 'QrCode',
      component: () => import('@/view/qrCode/QrCode.vue'),
      meta: { auth: false },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters['users/isLogin']) {
    next('/');
    return;
  } else if (to.meta.auth && to.name !== 'RoleSelection' && store.getters['users/isLoginAndCurrentCompanyNone']) {
    next('/role-selection');
    return;
  } else if (to.meta.hasMultiManagerRoles && !store.getters['users/hasMultiManagerRoles']) {
    next('/forbidden');
    return;
  } else if (to.meta.isSupervisorOrFactory && !store.getters['users/isSupervisorOrFactory']) {
    next('/forbidden');
    return;
  } else if (to.meta.isFactoryComp && !store.getters['users/isFactoryComp']) {
    next('/forbidden');
    return;
  }
  next();
});

export default router;
