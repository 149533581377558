<template>
  <section :class="{ 'showed-tree-list': isShowTreeList }">
    <ul class="general-lnb">
      <li v-for="(lnb, index) in lnbList" :key="index">
        <button v-if="lnb.name === 'remote'" @click="onClickLnbRemote">
          <span v-html="lnb['svgIcon']" />
          <span class="lnb-title">{{ lnb.title }}</span>
        </button>
        <router-link v-else :to="lnb.link">
          <span v-html="lnb['svgIcon']" />
          <span class="lnb-title">{{ lnb.title }}</span>
        </router-link>
      </li>
    </ul>
    <ul class="tree-list" v-show="isShowTreeList">
      <li v-for="(comp, key) in eqpGroupsByCompany" :key="key">
        <h6>{{ comp.compTitle }}</h6>
        <ul>
          <li v-for="(eqpGroup, index) in comp.eqpGroups" :key="index" :class="{ 'opened-eqp-list': eqpGroup.isOpen }">
            <span @click="eqpGroup.isOpen = !eqpGroup.isOpen">
              <button class="arrow_down" v-show="eqpGroup.isOpen">
                <img src="/images/arrow_drop_down-white.svg" alt="Arrow Down Icon" />
              </button>
              <button class="arrow_right" v-show="!eqpGroup.isOpen">
                <img src="/images/arrow_right-white.svg" alt="Arrow Down Icon" />
              </button>
              {{ eqpGroup.name }}
            </span>
            <ul>
              <li
                v-for="(eqp, index) in eqpGroup.equipments"
                :class="{ disabled: eqp.isActive === false }"
                :key="index"
                @click="onSelectEquipment(eqpGroup, eqp)"
              >
                <router-link :to="getTreeLink(eqp)" :class="{ active: Number($route.query.eqp) === eqp.id }">
                  {{ eqp.equipmentName }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import LnbData from './LnbData.json';
import isEmptyObject from '@/asset/js/isEmptyObject';
import { REMOTE_URL } from '@/const/apiUrl';

export default {
  name: 'Sidebar',
  props: [],
  data() {
    return {
      lnbList: [],
      eqpGroupsByCompany: {
        intdEqpGroup: {
          compTitle: '도입설비 그룹',
          eqpGroups: [],
        },
        selfEqpGroup: {
          compTitle: '자체설비 그룹',
          eqpGroups: [],
        },
      },
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapState('auth', ['rfToken']),
    ...mapGetters('users', ['isLogin', 'isManufacturerComp', 'isFactoryComp', 'isSupervisor', 'isStaff', 'isManager']),
    isShowTreeList() {
      return ['check-sheet', 'ar-contents'].includes(this.$route.meta.lnb);
    },
  },
  created() {
    this.$nextTick(() => {
      this.setLnbList();
      this.getEqpsByGroup();
    });
  },
  mounted() {},
  watch: {
    $route(newVal, oldVal) {
      this.setLnbList();

      if (newVal.meta['lnb'] !== oldVal.meta['lnb']) {
        this.SET_SELECTED_EQP_BY_GROUP({});
        this.getEqpsByGroup();
      } else {
        this.eqpGroupsByCompany['intdEqpGroup'].eqpGroups.forEach((group) => {
          this.setIsOpenEqpTree(group);
        });
        this.eqpGroupsByCompany['selfEqpGroup'].eqpGroups.forEach((group) => {
          this.setIsOpenEqpTree(group);
        });
      }
    },
  },
  methods: {
    ...mapMutations('factoryEquipments', ['SET_SELECTED_EQP_BY_GROUP', 'SET_HAS_EQP_BY_GROUP']),
    ...mapActions('factoryEquipments', ['GET_FACTORY_EQPS_BY_GROUP']),
    ...mapActions('factorySelfEquipments', ['GET_FACTORY_EQPS_SELF_BY_GROUP']),
    onClickLnbRemote() {
      this.$cookies.set('msf-sso-token', this.rfToken, null, null, 'msf.maxst.com');
      this.$cookies.set('current-company', this.currentCompany, null, null, 'msf.maxst.com');
      window.open(REMOTE_URL);
    },
    onSelectEquipment(eqpGroup, eqp) {
      this.SET_SELECTED_EQP_BY_GROUP({ eqpGroup, eqp });
    },
    getTreeLink(eqp) {
      switch (this.$route.meta.lnb) {
        case 'check-sheet':
          return { name: 'CheckSheetList', query: { eqp: eqp['id'] } };
        case 'ar-contents':
          return { name: 'ArContentsList', query: { eqp: eqp['id'], project: eqp['manualProjectId'] } };
        default:
          return {};
      }
    },
    async getEqpsByGroup() {
      if (this.isShowTreeList) {
        await this.getFactoryEqpsByGroup();
        await this.getFactoryEqpsSelfByGroup();
        this.setHasEqpByGroup();
      }
    },
    setHasEqpByGroup() {
      if (
        this.eqpGroupsByCompany['intdEqpGroup'].eqpGroups.length === 0 &&
        this.eqpGroupsByCompany['selfEqpGroup'].eqpGroups.length === 0
      ) {
        this.SET_HAS_EQP_BY_GROUP(false);
      } else {
        this.SET_HAS_EQP_BY_GROUP(true);
      }
    },
    setLnbList() {
      if (!isEmptyObject(this.myInfo) && this.$route.meta.gnb) {
        this.lnbList = LnbData[this.$route.meta.gnb].filter((lnb) => {
          switch (this.currentCompany) {
            case 'M':
              return this.isSupervisor ? lnb['isShowSupervisor'] : lnb['isShowStaff'];
            case 'F':
              return lnb['isShowManager'];
          }
        });
      }
    },
    async getFactoryEqpsByGroup() {
      const eqpsByGroup = await this.GET_FACTORY_EQPS_BY_GROUP();
      this.eqpGroupsByCompany['intdEqpGroup'].eqpGroups = eqpsByGroup.map((group) => {
        group.isOpen = false;
        this.setIsOpenEqpTree(group);
        return group;
      });
    },
    async getFactoryEqpsSelfByGroup() {
      const selfEqpsByGroup = await this.GET_FACTORY_EQPS_SELF_BY_GROUP();
      this.eqpGroupsByCompany['selfEqpGroup'].eqpGroups = selfEqpsByGroup.map((group) => {
        group.isOpen = false;
        this.setIsOpenEqpTree(group);
        return group;
      });
    },
    setIsOpenEqpTree(group) {
      const eqpId = this.$route.query.eqp;
      if (eqpId) {
        let currentEqp;
        const hasCurrentEqp = group['equipments'].some((eqp) => {
          if (eqp.id === Number(this.$route.query.eqp)) {
            currentEqp = eqp;
            return true;
          }
        });

        if (hasCurrentEqp) {
          group.isOpen = true;
          this.onSelectEquipment(group, currentEqp);
        }
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'Sidebar';
</style>
