import { factoryUsers } from '@/api';

function getFactoryUsers(sortOrder, pageNum, pageSize, keyword) {
  return factoryUsers.get(`?sortOrder=${sortOrder}&page=${pageNum}&pageSize=${pageSize}&keyword=${keyword}`);
}

function getFactoryUser(userId) {
  return factoryUsers.get(`${userId}`);
}

function postFactoryUser(user) {
  return factoryUsers.post(``, user);
}

function postFactoryUserRole(userRole) {
  return factoryUsers.post(`giveRole`, userRole);
}

function putFactoryUser(userId, user) {
  return factoryUsers.put(`${userId}`, user);
}

function getFactoryUserEqps(userId) {
  return factoryUsers.get(`/${userId}/equipments`);
}

function deleteFactoryUser(userId) {
  return factoryUsers.delete(`${userId}`);
}

function getFactoryUserName() {
  return factoryUsers.get(`username`);
}

export {
  getFactoryUsers,
  postFactoryUser,
  postFactoryUserRole,
  getFactoryUser,
  putFactoryUser,
  getFactoryUserEqps,
  deleteFactoryUser,
  getFactoryUserName,
};
