import { manufacturerUsers } from '@/api';

function getManufacturerUsers(sortOrder, pageNum, pageSize, keyword) {
  return manufacturerUsers.get(`?sortOrder=${sortOrder}&page=${pageNum}&pageSize=${pageSize}&keyword=${keyword}`);
}

function getManufacturerUser(userId) {
  return manufacturerUsers.get(`${userId}`);
}

function getManufacturerUserEqps(userId) {
  return manufacturerUsers.get(`/${userId}/equipments`);
}

function postManufacturerUser(user) {
  return manufacturerUsers.post(``, user);
}

function postManufacturerUserRole(userRole) {
  return manufacturerUsers.post(`giveRole`, userRole);
}

function putManufacturerUser(userId, user) {
  return manufacturerUsers.put(`${userId}`, user);
}

function deleteManufacturerUser(userId) {
  return manufacturerUsers.delete(`${userId}`);
}

function getManufacturerUserName() {
  return manufacturerUsers.get(`username`);
}

export {
  getManufacturerUsers,
  postManufacturerUser,
  postManufacturerUserRole,
  getManufacturerUser,
  putManufacturerUser,
  getManufacturerUserEqps,
  deleteManufacturerUser,
  getManufacturerUserName,
};
