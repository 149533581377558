export default (e, pw) => {
  if (!e.target.classList.contains('material-icon')) {
    return;
  }

  let $INPUT_PW = document.getElementById(pw);
  let $VISIBLE = e.target.closest("span[data-icon='visibility']");
  let $UN_VISIBLE = e.target.closest("span[data-icon='visibility_off']");
  let $ICON_SPANS = e.target.parentElement.parentElement.children;

  for (let i = 0; i < $ICON_SPANS.length; i++) {
    $ICON_SPANS[i].classList.remove('hide');
  }

  if ($INPUT_PW.type === 'password') {
    $INPUT_PW.type = 'text';
    $UN_VISIBLE.classList.add('hide');
  } else {
    $INPUT_PW.type = 'password';
    $VISIBLE.classList.add('hide');
  }
};
