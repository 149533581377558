import {
  getCheckHistories,
  getCheckHistoryExcel,
  getCheckHistoryCalendar,
  getActionRequireCalendar,
  getActionRequireDetail,
} from '@/api/modules/checkSheet/chkHists';
import getErrorMessage from '@/api/getErrorMessage';

const state = {
  checkHistoryDateRange: JSON.parse(sessionStorage.getItem('checkHistoryDateRange')) || {},
};

const getters = {};

const mutations = {
  SET_CHECK_HISTORY_DATE_RANGE(state, dateRange) {
    state.checkHistoryDateRange = dateRange;
    sessionStorage.setItem('checkHistoryDateRange', JSON.stringify(dateRange));
  },
  INIT_CHECK_HISTORY_DATE_RANGE(state) {
    state.checkHistoryDateRange = {};
    sessionStorage.removeItem('checkHistoryDateRange');
  },
};

const actions = {
  // GET : 특정 범위 일시의 점검 기록 요청
  async GET_CHECK_HISTORIES({ commit }, [startDT, endDT]) {
    try {
      const { data } = await getCheckHistories(startDT, endDT);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 엑셀 다운로드
  async GET_CHECK_HISTORY_EXCEL({ commit }, [startDT, endDT]) {
    try {
      return await getCheckHistoryExcel(startDT, endDT);
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 점검 이력 - 점검 현황 캘린더 조회
  async GET_CHECK_HISTORY_CALENDAR({ commit }, [startDT, endDT, keyword]) {
    try {
      const { data } = await getCheckHistoryCalendar(startDT, endDT, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 점검 이력 - 특이 사항 캘린더 조회
  async GET_ACTION_REQUIRE_CALENDAR({ commit }, [startDT, endDT, keyword]) {
    try {
      const { data } = await getActionRequireCalendar(startDT, endDT, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 점검 이력 - 특이 사항 상세 내역 조회
  async GET_ACTION_REQUIRE_DETAIL({ commit }, [startDT, endDT]) {
    try {
      const { data } = await getActionRequireDetail(startDT, endDT);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
