import {
  getManufacturerUsers,
  postManufacturerUser,
  postManufacturerUserRole,
  getManufacturerUser,
  putManufacturerUser,
  getManufacturerUserEqps,
  deleteManufacturerUser,
  getManufacturerUserName,
} from '@/api/modules/manufacturer/users';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 설비 회사 사용자 목록 조회
  async GET_MANUFACTURER_USERS({ commit }, [sortOrder, page, pageSize, keyword]) {
    try {
      const { data } = await getManufacturerUsers(sortOrder, page, pageSize, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 사용자 상세 조회
  async GET_MANUFACTURER_USER({ commit }, userId) {
    try {
      const { data } = await getManufacturerUser(userId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 사용자 담당 설비 목록 조회
  async GET_MANUFACTURER_USER_EQPS({ commit }, userId) {
    try {
      const { data } = await getManufacturerUserEqps(userId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 설비 회사 사용자 생성
  async POST_MANUFACTURER_USER({ commit }, user) {
    try {
      const { data } = await postManufacturerUser(user);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 이미 등록된 설비회사 / 권한이 없는 도입회사 담당자에게 설비회사 권한을 추가하여 설비회사 담당자로 등록
  async POST_MANUFACTURER_USER_ROLE({ commit }, userRole) {
    try {
      const { data } = await postManufacturerUserRole(userRole);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 설비 회사 사용자 정보 수정
  async PUT_MANUFACTURER_USER({ commit }, [userId, user]) {
    try {
      const { data } = await putManufacturerUser(userId, user);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 설비 회사 사용자 삭제
  async DELETE_MANUFACTURER_USER({ commit }, userId) {
    try {
      const { data } = await deleteManufacturerUser(userId);
      return data;
    } catch (error) {
      if (error.response.status !== 400) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 사용자 이름 목록 가져오기 (드롭다운)
  async GET_MANUFACTURER_USER_NAME({ commit }) {
    try {
      const { data } = await getManufacturerUserName();
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
