<template>
  <ul class="header-dropdown user-menu-list">
    <li class="text-overflow-ellipsis">{{ myInfo.companyName }}</li>
    <li>
      <span class="text-overflow-ellipsis" :class="{ 'user-name-width': hasMultiManagerRoles }">
        {{ myInfo.userName }}
      </span>
      <span v-if="hasMultiManagerRoles" @click="$emit('togglePopup', 'RoleChangePopup')">계정 역할 변경</span>
    </li>
    <li>
      <span>{{ role }}</span>
      <span class="text-overflow-ellipsis">{{ myInfo.phone }}</span>
      <span class="text-overflow-ellipsis">{{ myInfo.loginId }}</span>
    </li>
    <li @click="$emit('togglePopup', 'ProfileImagePopup')" class="mt-5">
      <span data-icon="person">
        <vue-material-icon name="person" :size="12" />
      </span>
      <span>프로필 변경</span>
    </li>
    <li @click="$emit('togglePopup', 'ChangePhonePopup')">
      <span data-icon="phone_iphone">
        <vue-material-icon name="phone_iphone" :size="12" />
      </span>
      <span>연락처 변경</span>
    </li>
    <li @click="$emit('togglePopup', 'ChangePasswordPopup')" class="mb-5">
      <span data-icon="vpn_key">
        <vue-material-icon name="vpn_key" :size="12" />
      </span>
      <span>비밀번호 변경</span>
    </li>
    <li class="mt-5" @click="onMovePage('terms-of-use')">서비스이용약관</li>
    <li @click="onMovePage('privacy-policy')">개인정보처리방침</li>
    <li @click="$emit('togglePopup', 'ConfirmPopup', 'LOGOUT')">
      <span>
        <img src="/images/logout.svg" alt="logout" />
      </span>
      <span>로그아웃</span>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MyInfo',
  props: ['myInfo', 'role'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('users', ['hasMultiManagerRoles']),
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onMovePage(pageName) {
      this.$router.push('/' + pageName).catch((err) => err);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'MyInfo';
</style>
