import { factorySelfEquipments } from '@/api';

function getFactoryEqpsSelf(pageNum, pageSize, sortOrder, keyword) {
  return factorySelfEquipments.get(`?page=${pageNum}&pageSize=${pageSize}&sortOrder=${sortOrder}&keyword=${keyword}`);
}

function getFactoryEqpSelf(eqpId) {
  return factorySelfEquipments.get(`${eqpId}`);
}

function postFactoryEqpSelf(eqp) {
  return factorySelfEquipments.post(``, eqp);
}

function putFactoryEqpSelf(eqpId, eqp) {
  return factorySelfEquipments.put(`${eqpId}`, eqp);
}

function getFactoryEqpsSelfByGroup() {
  return factorySelfEquipments.get(`bygroup`);
}

export { getFactoryEqpsSelf, getFactoryEqpSelf, postFactoryEqpSelf, putFactoryEqpSelf, getFactoryEqpsSelfByGroup };
