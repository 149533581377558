import { manualTasks, manualProjects } from '@/api';

function getAllTasks() {
  return manualTasks.get(``);
}

function getProjectTasks(projectId) {
  return manualProjects.get(`${projectId}/manualTasks`);
}

function getTask(taskId) {
  return manualTasks.get(`${taskId}`);
}

function putTask(taskId, task) {
  return manualTasks.put(`${taskId}`, task);
}

function putTaskCustom(taskId, customStr, isPublishing) {
  return manualTasks.put(`${taskId}?isPublished=${isPublishing}`, {
    custom: customStr,
  });
}

function putTaskPublish(taskId, isPublishing) {
  return manualTasks.put(`${taskId}?isPublished=${isPublishing}`);
}

function postTask(projectId, task) {
  return manualTasks.post(`?manualProjectId=${projectId}`, task);
}

function deleteTask(taskId) {
  return manualTasks.delete(`${taskId}`);
}

function duplicateTask(taskId, projectId) {
  return manualTasks.post(`${taskId}/duplicate?toManualProjectId=${projectId}`);
}

function moveTask(taskId, projectId) {
  return manualTasks.put(`${taskId}/move?toManualProjectId=${projectId}`);
}

function putTaskGuide(taskId, allFilename, fileBase64) {
  return manualTasks.put(`${taskId}/guide`, {
    rscName: allFilename,
    rscFileData: fileBase64,
  });
}

function deleteTaskGuide(taskId) {
  return manualTasks.delete(`${taskId}/guide`);
}

function postTaskContent(taskId, imgInfo) {
  return manualTasks.post(`${taskId}/content`, imgInfo);
}

export {
  getAllTasks,
  getProjectTasks,
  getTask,
  putTask,
  putTaskCustom,
  putTaskPublish,
  postTask,
  deleteTask,
  duplicateTask,
  moveTask,
  putTaskGuide,
  deleteTaskGuide,
  postTaskContent,
};
