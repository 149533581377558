import { manufacturerEqpGroupsAlert } from '@/api';

function postManufacturerAlert(alert) {
  return manufacturerEqpGroupsAlert.post(``, alert);
}

function putManufacturerAlert(alertId, alert) {
  return manufacturerEqpGroupsAlert.put(`${alertId}`, alert);
}

function deleteManufacturerAlert(alertId) {
  return manufacturerEqpGroupsAlert.delete(`${alertId}`);
}

function postManufacturerAlertRsc(rsc) {
  return manufacturerEqpGroupsAlert.post(`rsc`, rsc);
}

function deleteManufacturerAlertRsc(rscId) {
  return manufacturerEqpGroupsAlert.delete(`rsc/${rscId}`);
}

export {
  putManufacturerAlert,
  deleteManufacturerAlert,
  postManufacturerAlert,
  postManufacturerAlertRsc,
  deleteManufacturerAlertRsc,
};
