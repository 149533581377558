import { manufacturerSensors } from '@/api';

function getManufacturerSensors(page, pageSize, sortOrder, keyword) {
  return manufacturerSensors.get(`?page=${page}&pageSize=${pageSize}&sortOrder=${sortOrder}&keyword=${keyword}`);
}

function getManufacturerSensor(sensorId) {
  return manufacturerSensors.get(`${sensorId}`);
}

function postManufacturerSensor(sensor) {
  return manufacturerSensors.post(``, sensor);
}

function putManufacturerSensor(sensorId, sensor) {
  return manufacturerSensors.put(`${sensorId}`, sensor);
}

function deleteManufacturerSensor(sensorId) {
  return manufacturerSensors.delete(`${sensorId}`);
}

function getManufacturerSensorModel() {
  return manufacturerSensors.get(`modelname`);
}

export {
  getManufacturerSensors,
  getManufacturerSensor,
  postManufacturerSensor,
  putManufacturerSensor,
  deleteManufacturerSensor,
  getManufacturerSensorModel,
};
