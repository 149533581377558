import {
  getManufacturerSensors,
  getManufacturerSensor,
  postManufacturerSensor,
  deleteManufacturerSensor,
  putManufacturerSensor,
  getManufacturerSensorModel,
} from '@/api/modules/manufacturer/sensors';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 설비 회사 센서 목록 조회
  async GET_MANUFACTURER_SENSORS({ commit }, [page, pageSize, sortOrder, keyword]) {
    try {
      const { data } = await getManufacturerSensors(page, pageSize, sortOrder, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 센서 상세
  async GET_MANUFACTURER_SENSOR({ commit }, sensorId) {
    try {
      const { data } = await getManufacturerSensor(sensorId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 설비 회사 센서 등록
  async POST_MANUFACTURER_SENSOR({ commit }, sensor) {
    try {
      const { data } = await postManufacturerSensor(sensor);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 설비 회사 센서 수정
  async PUT_MANUFACTURER_SENSOR({ commit }, [sensorId, sensor]) {
    try {
      const { data } = await putManufacturerSensor(sensorId, sensor);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 설비 회사 센서 삭제
  async DELETE_MANUFACTURER_SENSOR({ commit }, sensorId) {
    try {
      const { data } = await deleteManufacturerSensor(sensorId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 센서 모델명 목록 조회 (드롭다운)
  async GET_MANUFACTURER_SENSOR_MODEL({ commit }) {
    try {
      const { data } = await getManufacturerSensorModel();
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
