import { callHistories, callHistoriesBlob } from '@/api';

function getCallHistories(keyword, startDT, endDT, page, pageSize, companyType) {
  return callHistories.get(
    `?keyword=${keyword}&startDT=${startDT}&endDT=${endDT}&page=${page}&pageSize=${pageSize}&companyType=${companyType}`,
  );
}

function getCallHistoryContents(callId) {
  return callHistories.get(`${callId}/contents`);
}

function getDownloadCallHistoryContent(callId, contentId) {
  return callHistoriesBlob.get(`${callId}/contents/fs/${contentId}`);
}

function deleteCallHistoryContent(contentId) {
  return callHistories.delete(`contents/${contentId}`);
}

export { getCallHistories, getCallHistoryContents, getDownloadCallHistoryContent, deleteCallHistoryContent };
