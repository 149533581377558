import {
  getFactoryEqpsSelf,
  postFactoryEqpSelf,
  getFactoryEqpSelf,
  putFactoryEqpSelf,
  getFactoryEqpsSelfByGroup,
} from '@/api/modules/factory/selfEquipments';
import getErrorMessage from '@/api/getErrorMessage';

const state = {
  selectedEqpByGroup: JSON.parse(sessionStorage.getItem('selectedEqpByGroup')) || {},
  hasEqpByGroup: null,
};

const getters = {};

const mutations = {};

const actions = {
  // GET : 자체 설비 목록 조회
  async GET_FACTORY_EQPS_SELF({ commit }, [page, pageSize, sortOrder, keyword]) {
    try {
      const { data } = await getFactoryEqpsSelf(page, pageSize, sortOrder, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 자체 설비 상세
  async GET_FACTORY_EQP_SELF({ commit }, eqpId) {
    try {
      const { data } = await getFactoryEqpSelf(eqpId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 자체 설비 등록
  async POST_FACTORY_EQP_SELF({ commit }, eqp) {
    try {
      const { data } = await postFactoryEqpSelf(eqp);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 자체 설비 수정
  async PUT_FACTORY_EQP_SELF({ commit }, [eqpId, eqp]) {
    try {
      const { data } = await putFactoryEqpSelf(eqpId, eqp);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 설비 그룹 별 자체 설비 목록 조회
  async GET_FACTORY_EQPS_SELF_BY_GROUP({ commit }) {
    try {
      const { data } = await getFactoryEqpsSelfByGroup();
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
