<template>
  <general-simple-popup v-if="isShow">
    <h2 slot="title">계정 역할 변경</h2>
    <div slot="contents" class="color-gray700">
      <p>
        <b>{{ myInfo.companyName }} {{ myInfo.userName }}({{ myInfo.loginId }})</b>
        님
      </p>
      <p>
        현재 {{ currentCompanyName }} {{ currentRole }}로 MSF를 사용 중입니다.
        <br />
        {{ changeCompany }} {{ changeRole }}로 변경하시겠습니까?
      </p>
    </div>
    <button-basic slot="button" color="gray-border-1" @click="onClosePopup">취소</button-basic>
    <button-basic slot="button" color="primary" @click="onChangeRoleAndMovePage">
      {{ $t('message.confirm_btn') }}
    </button-basic>
  </general-simple-popup>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import GeneralSimplePopup from '@/component/popup/generalSimplePopup/GeneralSimplePopup';

export default {
  name: 'RoleChangePopup',
  props: ['isShow'],
  data() {
    return {
      currentCompanyName: '',
      currentRole: '',
      changeCompany: '',
      changeRole: '',
      reloadPath: ['/users', '/equipments'],
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapGetters('users', ['isSupervisor']),
  },
  created() {},
  mounted() {
    this.onSetName();
  },
  watch: {
    $route() {
      this.onSetName();
    },
  },
  methods: {
    ...mapMutations('users', ['SET_MY_CURRENT_COMPANY']),
    onSetName() {
      switch (this.currentCompany) {
        case 'M':
          this.currentCompanyName = '설비회사';
          this.changeCompany = '도입회사';
          this.changeRole = '매니저';
          this.currentRole = this.isSupervisor ? '슈퍼바이저' : '스태프';
          break;
        case 'F':
          this.currentCompanyName = '도입회사';
          this.changeCompany = '설비회사';
          this.changeRole = this.isSupervisor ? '슈퍼바이저' : '스태프';
          this.currentRole = '매니저';
          break;
      }
    },
    async onChangeRoleAndMovePage() {
      this.$emit('close');
      let nextPagePath = '';
      switch (this.currentCompany) {
        case 'M':
          await this.SET_MY_CURRENT_COMPANY('F');
          nextPagePath = '/equipment-groups';
          break;
        case 'F':
          await this.SET_MY_CURRENT_COMPANY('M');
          nextPagePath = this.changeRole === '슈퍼바이저' ? '/users' : '/equipments';
          break;
      }
      if (this.$route.path === nextPagePath) {
        this.$router.go(0);
      } else {
        this.$router.push(nextPagePath).catch((err) => err);
      }
    },
    onClosePopup() {
      this.$emit('close');
    },
  },
  components: { GeneralSimplePopup },
};
</script>
