import { requestToken } from '@/api/modules/common/auth';

const state = {
  token: sessionStorage.getItem('token') || '',
  rfToken: sessionStorage.getItem('rfToken') || '',
  nextPage: sessionStorage.getItem('nextPage') || '',
};

const getters = {};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
    sessionStorage.setItem('token', token);
  },
  SET_REFRESH_TOKEN(state, rfToken) {
    state.rfToken = rfToken;
    sessionStorage.setItem('rfToken', rfToken);
  },
  INIT_TOKENS(state) {
    state.token = '';
    state.rfToken = '';
  },
  SET_NEXT_PAGE(state, nextPage) {
    state.nextPage = nextPage;
    sessionStorage.setItem('nextPage', nextPage);
  },
  INIT_NEXT_PAGE(state) {
    state.nextPage = '';
  },
};

const actions = {
  async POST_REQUEST_TOKEN({ commit }, userData) {
    try {
      const reqData = userData || { rfToken: state.rfToken };
      const res = (await requestToken(reqData)) || { data: { token: '', rfToken: '' } };
      commit('SET_TOKEN', res.data.token);
      commit('SET_REFRESH_TOKEN', res.data.rfToken);
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
