var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "popup-wrapper" }, [
      _c("div", { staticClass: "popup" }, [
        _c(
          "div",
          { staticClass: "popup-header" },
          [
            _vm._t("title"),
            _vm.hasHeaderCloseButton
              ? _c(
                  "button",
                  {
                    staticClass: "close-button",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [
                    _c("vue-material-icon", {
                      attrs: { name: "close", size: 24 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "popup-body" }, [
          _c(
            "div",
            { staticClass: "popup-body-contents" },
            [_vm._t("contents")],
            2
          ),
          _c(
            "div",
            { staticClass: "popup-body-buttons" },
            [_vm._t("button")],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }