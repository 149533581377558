import {
  postFactoryEqpReject,
  postFactoryEqpApprove,
  getFactoryEqp,
  putFactoryEqp,
  getFactoryEqps,
  getFactoryEqpsWait,
  getFactoryEqpsByGroup,
  deleteFactoryEqp,
} from '@/api/modules/factory/equipments';
import getErrorMessage from '@/api/getErrorMessage';

const state = {
  selectedEqpByGroup: JSON.parse(sessionStorage.getItem('selectedEqpByGroup')) || {},
  hasEqpByGroup: null,
};

const getters = {};

const mutations = {
  SET_SELECTED_EQP_BY_GROUP(state, eqpByGroup) {
    state.selectedEqpByGroup = eqpByGroup;
    sessionStorage.setItem('selectedEqpByGroup', JSON.stringify(eqpByGroup));
  },
  SET_HAS_EQP_BY_GROUP(state, hasEqpByGroup) {
    state.hasEqpByGroup = hasEqpByGroup;
  },
};

const actions = {
  // GET : 도입 승인 완료 설비 목록 조회
  async GET_FACTORY_EQPS({ commit }, [sortOrder, page, pageSize, keyword]) {
    try {
      const { data } = await getFactoryEqps(sortOrder, page, pageSize, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 도입 설비 승인대기 목록 조회
  async GET_FACTORY_EQPS_WAIT({ commit }) {
    try {
      const { data } = await getFactoryEqpsWait();
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 도입 설비 상세
  async GET_FACTORY_EQP({ commit }, eqpId) {
    try {
      const { data } = await getFactoryEqp(eqpId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // PUT : 도입 설비 수정
  async PUT_FACTORY_EQP({ commit }, [eqpId, eqp]) {
    try {
      const { data } = await putFactoryEqp(eqpId, eqp);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 설비 삭제
  async DELETE_FACTORY_EQP({ commit }, eqpId) {
    try {
      const { data } = await deleteFactoryEqp(eqpId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 설비 그룹 별 도입 설비명 및 create 프로젝트 id 목록 조회 (승인완료설비목록)
  async GET_FACTORY_EQPS_BY_GROUP({ commit }) {
    try {
      const { data } = await getFactoryEqpsByGroup();
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 도입 설비 반려
  async POST_FACTORY_EQP_REJECT({ commit }, eqpId) {
    try {
      const { data } = await postFactoryEqpReject(eqpId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 도입 설비 승인
  async POST_FACTORY_EQP_APPROVE({ commit }, [eqpId, eqp]) {
    try {
      const { data } = await postFactoryEqpApprove(eqpId, eqp);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
