import {
  getFactoryUsers,
  postFactoryUser,
  postFactoryUserRole,
  getFactoryUser,
  putFactoryUser,
  getFactoryUserEqps,
  deleteFactoryUser,
  getFactoryUserName,
} from '@/api/modules/factory/users';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 도입 회사 사용자 목록 조회
  async GET_FACTORY_USERS({ commit }, [sortOrder, page, pageSize, keyword]) {
    try {
      const { data } = await getFactoryUsers(sortOrder, page, pageSize, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 도입 회사 사용자 상세
  async GET_FACTORY_USER({ commit }, userId) {
    try {
      const { data } = await getFactoryUser(userId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 도입 회사 사용자 담당 설비 목록 조회
  async GET_FACTORY_USER_EQPS({ commit }, userId) {
    try {
      const { data } = await getFactoryUserEqps(userId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 도입 회사 사용자 생성
  async POST_FACTORY_USER({ commit }, user) {
    try {
      const { data } = await postFactoryUser(user);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 이미 등록된 사용자에게 도입 회사 권한만 추가하여 도입 회사 사용자로 등록
  async POST_FACTORY_USER_ROLE({ commit }, userRole) {
    try {
      const { data } = await postFactoryUserRole(userRole);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 도입 회사 사용자 정보 수정
  async PUT_FACTORY_USER({ commit }, [userId, user]) {
    try {
      const { data } = await putFactoryUser(userId, user);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 도입 회사 사용자 삭제
  async DELETE_FACTORY_USER({ commit }, userId) {
    try {
      const { data } = await deleteFactoryUser(userId);
      return data;
    } catch (error) {
      if (error.response.status !== 400) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 도입 회사 사용자 이름 목록 조회 (드롭다운)
  async GET_FACTORY_USER_NAME({ commit }) {
    try {
      const { data } = await getFactoryUserName();
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
