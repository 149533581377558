<template>
  <transition name="modal">
    <div class="popup-wrapper">
      <div class="popup">
        <div class="popup-header">
          <slot name="title" />
          <button v-if="hasHeaderCloseButton" class="close-button" @click="$emit('close')">
            <vue-material-icon name="close" :size="24" />
          </button>
        </div>
        <div class="popup-body">
          <div class="popup-body-contents">
            <slot name="contents" />
          </div>
          <div class="popup-body-buttons">
            <slot name="button" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'GeneralSimplePopup',
  props: ['hasHeaderCloseButton'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style scoped lang="scss">
@import 'GeneralSimplePopup';
</style>
