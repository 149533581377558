export default (_this, target, isAllowKorean = true, hasLimitFileName = true) => {
  const fileRoute = target.value;
  const pathHeader = fileRoute.lastIndexOf('\\');
  const pathMiddle = fileRoute.lastIndexOf('.');
  const pathEnd = fileRoute.length;
  const originFileName = fileRoute.substring(pathHeader + 1, pathMiddle);
  let fileName = isAllowKorean ? originFileName : originFileName.replace(/[^a-zA-Z0-9_]/g, '_');
  const extName = fileRoute.substring(pathMiddle + 1, pathEnd);
  let fileTypes; // 파일 타입 : png, jpg, mp4, pdf
  let fileSizeLimit; // 용량 제한
  let fileKinds; // 파일 종류 : 이미지, 동영상, 문서
  const isImageType = target.accept.toLowerCase().indexOf('jpg') !== -1;
  const isVideoType = target.accept.toLowerCase().indexOf('mp4') !== -1;

  if (fileRoute) {
    if (isImageType) {
      fileTypes = 'PNG, JPG';
      fileSizeLimit = 10;
      fileKinds = '이미지';
    } else if (isVideoType) {
      fileTypes = 'MP4';
      fileSizeLimit = 100;
      fileKinds = '동영상';
    } else {
      fileTypes = 'PDF';
      fileSizeLimit = 200;
      fileKinds = '문서';
    }

    // 파일형식 제한
    if (extName && !fileTypes.includes(extName.toUpperCase())) {
      _this.isFrontErrorFileUpload = true;
      alert(`지원하지 않는 파일 형식입니다. 파일 형식이 ${fileTypes}인 ${fileKinds}만 사용할 수 있습니다.`);
    } else {
      // 파일명 20자 이상일 경우 문자열 자르기
      if (fileName.length > 20 && hasLimitFileName) {
        fileName = fileName.substring(0, 20);
      }
      _this.allFilename = fileName + '.' + extName;
      let fileObj = target.files[0];
      let fileSize = fileObj.size / 1024 / 1024;

      // 용량 제한
      if (fileSize > fileSizeLimit) {
        _this.isFrontErrorFileUpload = true;
        alert(`파일 용량이 초과되었습니다. 파일 용량이 ${fileSizeLimit}MB 미만인 ${fileKinds}만 사용할 수 있습니다.`);
      } else {
        // 모든 제한에서 통과
        let reader = new FileReader();
        reader.readAsDataURL(fileObj);
        _this.isFrontErrorFileUpload = false;

        //로드 한 후
        reader.onload = () => {
          _this.imageUrl = reader.result;
          _this.fileBase64 = reader.result.split(',')[1];
          _this.targetId = null; // task 이미지 파일을 바꿀 경우 targetId null로 지정
          if (_this.uploadFile) _this.uploadFile();
        };
      }
    }
  }
};
