import {
  putManufacturerAlert,
  deleteManufacturerAlert,
  postManufacturerAlert,
  postManufacturerAlertRsc,
  deleteManufacturerAlertRsc,
} from '@/api/modules/manufacturer/alert';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // POST : 설비 회사 알림 설정 등록
  async POST_MANUFACTURER_ALERT({ commit }, alert) {
    try {
      const { data } = await postManufacturerAlert(alert);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 설비 회사 알림 설정 수정
  async PUT_MANUFACTURER_ALERT({ commit }, [alertId, alert]) {
    try {
      const { data } = await putManufacturerAlert(alertId, alert);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 설비 회사 알림 설정 삭제
  async DELETE_MANUFACTURER_ALERT({ commit }, alertId) {
    try {
      const { data } = await deleteManufacturerAlert(alertId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 설비 회사 알림 설정 리소스 등록
  async POST_MANUFACTURER_ALERT_RSC({ commit }, rscObj) {
    try {
      const { data } = await postManufacturerAlertRsc(rscObj);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 설비 회사 알림 설정 리소스 삭제
  async DELETE_MANUFACTURER_ALERT_RSC({ commit }, rscId) {
    try {
      const { data } = await deleteManufacturerAlertRsc(rscId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
