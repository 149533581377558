<template>
  <transition name="modal">
    <general-simple-popup v-if="isShow">
      <h2 slot="title" v-if="data.title" v-html="data.title" />
      <p slot="contents" v-html="data.description" />
      <button-basic slot="button" color="dark" size="basic" @click="onClickButton">
        {{ $t(`message.confirm_btn`) }}
      </button-basic>
    </general-simple-popup>
  </transition>
</template>

<script>
import GeneralSimplePopup from '@/component/popup/generalSimplePopup/GeneralSimplePopup';
export default {
  name: 'ErrorPopup',
  props: ['isShow', 'data'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onClickButton() {
      this.$emit('close');
    },
  },
  components: { GeneralSimplePopup },
};
</script>
