import '@babel/polyfill';
import Vue from 'vue';
import App from '@/App';
import router from '@/router/index';
import VueMaterialIcon from 'vue-material-icon';
import store from '@/store';
import VueQrcode from 'vue-qrcode';
import VueDragDrop from 'vue-drag-drop';
import moment from 'moment';
import VueMomentJS from 'vue-momentjs';
import VueButtonAnimation from 'vue-button-animation';
import i18n from '@/translation';
import QrPrint from 'vue-qr-print';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import PlusIcon from 'vue-material-design-icons/Plus.vue';
import CameraIcon from 'vue-material-design-icons/Camera.vue';
import VueCookies from 'vue-cookies';

import {
  localDateYYYYMMDD,
  localDateYYYYMMDDTime,
  localDateYYYYMMDDTimeSecond,
  localDateYYYYMM,
  formatSecondsToFullTime,
  formatEQPCode,
  formatSensorCode,
  roleLabel,
} from '@/util/filters';

Vue.use(VueDragDrop);
Vue.use(VueButtonAnimation);
Vue.use(VueMomentJS, moment);
Vue.component(VueMaterialIcon.name, VueMaterialIcon);
Vue.component('vue-qr-code', VueQrcode);
Vue.use(QrPrint);
Vue.component('v-calendar', Calendar);
Vue.component('v-date-picker', DatePicker);
Vue.component('close-icon', CloseIcon);
Vue.component('plus-icon', PlusIcon);
Vue.component('camera-icon', CameraIcon);
Vue.use(VueCookies);

Vue.config.productionTip = false;

Vue.filter('localDateYYYYMMDD', localDateYYYYMMDD);
Vue.filter('localDateYYYYMMDDTime', localDateYYYYMMDDTime);
Vue.filter('localDateYYYYMMDDTimeSecond', localDateYYYYMMDDTimeSecond);
Vue.filter('localDateYYYYMM', localDateYYYYMM);
Vue.filter('formatSecondsToFullTime', formatSecondsToFullTime);
Vue.filter('formatEQPCode', formatEQPCode);
Vue.filter('formatSensorCode', formatSensorCode);
Vue.filter('roleLabel', roleLabel);

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: { App },
  template: '<App/>',
});
