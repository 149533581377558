import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enTranslation from './translationEn.json';
import koTranslation from './translationKo.json';
import language from '@/const/language';

Vue.use(VueI18n);

const messages = {
  en: {
    message: enTranslation,
  },
  ko: {
    message: koTranslation,
  },
};

Vue.prototype.$lang = language;

const i18n = new VueI18n({
  locale: language,
  messages,
});

export default i18n;
