<template>
  <general-function-popup v-if="isShow" :is-none-header-bg="true" :is-none-header-border="true">
    <h2 slot="title">연락처 변경</h2>
    <div slot="contents">
      <input-box
        id="phone"
        label="연락처 (핸드폰 번호)<span class='color-primary400'>*</span>"
        v-model="phone"
        autocomplete="off"
        maxlength="11"
        class="mb-40"
        defaultMessage="숫자만 입력해 주세요"
        is-height-large
      />
    </div>
    <button-basic slot="button" color="gray-border-1" @click="onClosePopup">
      {{ $t('message.cancel_btn') }}
    </button-basic>
    <button-basic slot="button" color="dark" @click="onChangePhone" :disabled="!phone" :loading-dot="isLoadingButton">
      변경
    </button-basic>
  </general-function-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import InputBox from '@/component/input/InputBox';

export default {
  name: 'ChangePhonePopup',
  props: ['isShow'],
  data() {
    return {
      phone: '',
      isLoadingButton: false,
    };
  },
  computed: {
    ...mapState('users', ['myInfo']),
  },
  created() {},
  mounted() {
    this.phone = this.myInfo.phone;
  },
  watch: {},
  methods: {
    ...mapActions('users', ['PUT_MY_INFO']),
    async onChangePhone() {
      try {
        this.isLoadingButton = true;
        const phoneData = {
          phone: this.phone,
        };
        await this.PUT_MY_INFO(phoneData);
        this.$emit('close');
      } finally {
        this.isLoadingButton = false;
      }
    },
    onClosePopup() {
      this.phone = this.myInfo.phone;
      this.$emit('close');
    },
  },
  components: { InputBox, GeneralFunctionPopup },
};
</script>

<style scoped lang="scss">
@import 'ChangePhonePopup';
</style>
