<template>
  <general-function-popup v-show="isShow" :is-none-header-bg="true" :is-none-header-border="true">
    <h2 slot="title">프로필 변경</h2>
    <div slot="contents">
      <div class="photo-wrapper">
        <div class="profile-image-wrapper">
          <img src="/images/profile_avatar.svg" alt="Profile Image" v-if="!imageUrl" />
          <img :src="imageUrl" id="preview" class="profile-image" alt="Profile Image" v-if="imageUrl" />
        </div>
        <file-basic color="lightgray" :circle="40" accept=".jpg, .png" id="upFileProfile" @change="onSelectFile">
          <camera-icon />
        </file-basic>
      </div>
      <ul class="profile-icons">
        <li
          :key="index"
          v-for="(iconSrc, index) in profileIcons"
          :class="{
            selected: getIsSelectedIcon(iconSrc, index),
          }"
          @click="onSelectProfileIcon($event, iconSrc, index)"
        >
          <img :src="iconSrc" alt="Profile Icon Sample" />
        </li>
      </ul>
    </div>
    <button-basic slot="button" color="gray-border-1" @click="onClosePopup">
      {{ $t('message.cancel_btn') }}
    </button-basic>
    <button-basic
      slot="button"
      color="dark"
      :loading-dot="isLoadingButton"
      :disabled="!imageUrl || !isChangeImage"
      @click="onSaveProfileImage"
    >
      변경
    </button-basic>
  </general-function-popup>
</template>
<script>
import fileUpload from '@/asset/js/fileUpload';
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ProfileImagePopup',
  props: ['isShow'],
  data() {
    return {
      imageUrl: '',
      allFilename: '',
      fileBase64: '',
      isChangeImage: false,
      isLoadingButton: false,
      selectedIconIndex: null,
      profileId: '',
    };
  },
  computed: {
    ...mapState('users', ['myInfo']),
    ...mapState('adminConfigs', ['profileIcons']),
  },
  created() {},
  mounted() {},
  watch: {
    isShow() {
      if (this.isShow) {
        this.imageUrl = this.myInfo.rscUrl;
        this.SET_IS_CHANGE_PROFILE(false);
      } else {
        this.initializeImage();
      }
    },
    fileBase64() {
      if (this.fileBase64) {
        this.isChangeImage = true;
        let imgObj = {
          rscName: this.allFilename,
          rscValue: this.fileBase64,
        };
        this.postUpdateMyPrfImage(imgObj);
      }
    },
  },
  methods: {
    ...mapMutations('users', ['SET_IS_CHANGE_PROFILE']),
    ...mapActions('users', ['PUT_CHANGE_PROFILE', 'POST_CHANGE_PROFILE']),
    getIsSelectedIcon(iconSrc, index) {
      return (
        index === this.selectedIconIndex ||
        (this.selectedIconIndex === null && !this.fileBase64 && iconSrc === this.myInfo.rscUrl)
      );
    },
    onSelectProfileIcon(e, iconSrc, index) {
      this.initializeImage();
      this.selectedIconIndex = index;
      this.imageUrl = iconSrc;
      this.isChangeImage = true;
    },
    initializeImage() {
      this.allFilename = '';
      this.fileBase64 = '';
      this.imageUrl = '';
      this.isChangeImage = false;
      this.selectedIconIndex = null;
      const upFileProfile = document.getElementById('upFileProfile');
      if (upFileProfile) {
        upFileProfile.value = '';
      }
    },
    onSelectFile(e) {
      this.selectedIconIndex = null;
      fileUpload(this, e.target, false, true);
    },
    onSaveProfileImage() {
      if (this.isChangeImage) {
        let imgObj = {};
        if (this.fileBase64) {
          imgObj = {
            rscId: this.profileId,
          };
        } else {
          imgObj = {
            prfImgURL: this.imageUrl,
          };
        }
        this.putUpdateMyPrfImage(imgObj);
      } else {
        this.$emit('close');
      }
    },
    async putUpdateMyPrfImage(imgObj) {
      try {
        this.isLoadingButton = true;
        await this.PUT_CHANGE_PROFILE(imgObj);
        this.$emit('close');
      } finally {
        this.isLoadingButton = false;
      }
    },
    async postUpdateMyPrfImage(imgObj) {
      try {
        this.isLoadingButton = true;
        this.profileId = await this.POST_CHANGE_PROFILE(imgObj);
      } finally {
        this.isLoadingButton = false;
      }
    },
    onClosePopup() {
      this.$emit('close');
    },
  },
  components: { GeneralFunctionPopup },
};
</script>

<style scoped lang="scss">
@import 'ProfileImagePopup';
</style>
