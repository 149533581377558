var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-label-wrapper", class: { "input-flex": _vm.isFlex } },
    [
      _c("label", {
        attrs: { for: _vm.id },
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
      _c(
        "div",
        {
          staticClass: "input-wrapper",
          class: {
            "error-border": _vm.errorMessage,
            "exist-value": _vm.isFocused || _vm.value,
            disabled: _vm.disabled,
            "height-large": _vm.isHeightLarge,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [
          _vm.name
            ? _c(
                "span",
                {
                  staticClass: "input-left-icon",
                  attrs: { "data-icon": "input-left-icon" },
                },
                [_c("vue-material-icon", { attrs: { name: _vm.name } })],
                1
              )
            : _vm._e(),
          _c("input", {
            attrs: {
              type: _vm.type,
              id: _vm.id,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              autocomplete: _vm.autocomplete,
              maxlength: _vm.maxlength,
              tabindex: _vm.tabindex,
              readonly: _vm.isReadonly,
            },
            domProps: {
              value:
                typeof _vm.value === "string" ? _vm.value.trim() : _vm.value,
            },
            on: {
              input: _vm.onChange,
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onKeyUpEnter.apply(null, arguments)
              },
              focus: function ($event) {
                return _vm.toggleInputFocused(true)
              },
              blur: function ($event) {
                return _vm.toggleInputFocused(false)
              },
            },
          }),
          _vm.type === "password"
            ? _c(
                "button",
                {
                  staticClass: "visibility-btn",
                  on: {
                    click: function ($event) {
                      return _vm.onChangeVisibility($event, _vm.id)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { "data-icon": "visibility_off" } },
                    [
                      _c("vue-material-icon", {
                        attrs: { name: "visibility_off" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "hide",
                      attrs: { "data-icon": "visibility" },
                    },
                    [
                      _c("vue-material-icon", {
                        staticClass: "visibility",
                        attrs: { name: "visibility" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.errorMessage || _vm.defaultMessage
        ? _c(
            "p",
            {
              staticClass: "info-text",
              class: { "error-text": _vm.errorMessage },
            },
            [
              _vm.iconY
                ? _c("vue-material-icon", { attrs: { name: "error" } })
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(
                    !_vm.errorMessage ? _vm.defaultMessage : _vm.errorMessage
                  ) +
                  " "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.textCount
        ? _c("p", { staticClass: "text-count" }, [
            _vm._v(_vm._s(_vm.value.length) + "/" + _vm._s(_vm.maxlength)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }