import {
  putFactoryAlert,
  deleteFactoryAlert,
  postFactoryAlert,
  postFactoryAlertRsc,
  deleteFactoryAlertRsc,
} from '@/api/modules/factory/alert';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // POST : 도입회사 알림설정 등록
  async POST_FACTORY_ALERT({ commit }, alert) {
    try {
      const { data } = await postFactoryAlert(alert);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 도입회사 알림설정 수정
  async PUT_FACTORY_ALERT({ commit }, [alertId, alert]) {
    try {
      const { data } = await putFactoryAlert(alertId, alert);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 도입회사 알림설정 삭제
  async DELETE_FACTORY_ALERT({ commit }, alertId) {
    try {
      const { data } = await deleteFactoryAlert(alertId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 도입회사 알림설정 리소스 등록
  async POST_FACTORY_ALERT_RSC({ commit }, rscObj) {
    try {
      const { data } = await postFactoryAlertRsc(rscObj);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 도입회사 알림설정 리소스 삭제
  async DELETE_FACTORY_ALERT_RSC({ commit }, rscId) {
    try {
      const { data } = await deleteFactoryAlertRsc(rscId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
