export const NONE_MENU_PROFILE_PAGES = ['RoleSelection'];
export const SIMPLE_PAGES = ['RoleSelection', 'ResetPassword', 'TermsOfUse', 'PrivacyPolicy', 'NotFound', 'Forbidden'];
export const NONE_HEADER_PAGES = [
  'Login',
  'OpenSourceLicenseAndroid',
  'OpenSourceLicenseiOS',
  'OpenSourceLicenseWeb',
  'QrCode',
];
export const NONE_SIDEBAR_PAGES = [
  ...SIMPLE_PAGES,
  'Login',
  'EquipmentMonitoring',
  'OpenSourceLicenseAndroid',
  'OpenSourceLicenseiOS',
  'OpenSourceLicenseWeb',
  'QrCode',
];
export const MOBILE_ACCESSIBLE_PAGES = [
  'TermsOfUse',
  'PrivacyPolicy',
  'OpenSourceLicenseAndroid',
  'OpenSourceLicenseiOS',
  'OpenSourceLicenseWeb',
  'QrCode',
];
export const LOGO_TO_LOGIN_PAGES = [...MOBILE_ACCESSIBLE_PAGES, 'ResetPassword'];
export const GRAY_HEADER_PAGES = ['RoleSelection', 'ResetPassword'];
