import axios from 'axios';
import { setInterceptors } from './interceptors';
import { CREATE_URL, CMS_URL, CHK_URL } from '@/const/apiUrl';

function createAxios() {
  return axios.create({
    baseURL: `${CMS_URL}/api/`,
    timeout: 180000,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
}

function createAxiosWithAuth(originUrl, url, responseType) {
  const axiosService = axios.create({
    baseURL: `${originUrl}/api/${url}`,
    timeout: 180000,
    responseType: responseType,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  return setInterceptors(axiosService);
}

// no auth
export const axiosService = createAxios();

// 공통
export const users = createAxiosWithAuth(CMS_URL, 'users');
export const adminConfigs = createAxiosWithAuth(CMS_URL, 'adminConfigs');

// 도입회사
export const factoryEqpGroupsAlert = createAxiosWithAuth(CMS_URL, 'factory/equipmentGroups/alertSettings');
export const factoryUsers = createAxiosWithAuth(CMS_URL, 'factory/users');
export const factoryEqpGroups = createAxiosWithAuth(CMS_URL, 'factory/equipmentGroups');
export const factoryEquipments = createAxiosWithAuth(CMS_URL, 'factory/equipments');
export const factorySelfEquipments = createAxiosWithAuth(CMS_URL, 'factory/equipments/self');
export const factorySensors = createAxiosWithAuth(CMS_URL, 'factory/sensors');

// 설비회사
export const manufacturerEqpGroupsAlert = createAxiosWithAuth(CMS_URL, 'manufacturer/equipmentGroups/alertSettings');
export const manufacturerEqpGroups = createAxiosWithAuth(CMS_URL, 'manufacturer/equipmentGroups');
export const manufacturerEquipments = createAxiosWithAuth(CMS_URL, 'manufacturer/equipments');
export const manufacturerUsers = createAxiosWithAuth(CMS_URL, 'manufacturer/users');
export const manufacturerSensors = createAxiosWithAuth(CMS_URL, 'manufacturer/sensors');

// cms 점검 이력
export const cmsChkHists = createAxiosWithAuth(CMS_URL, 'chk/w/chkHists');

// check sheet
export const actionRequireds = createAxiosWithAuth(CHK_URL, 'w/actionRequireds');
export const actionRscFiles = createAxiosWithAuth(CHK_URL, 'w/actionRequiredRscFiles');
export const chkHists = createAxiosWithAuth(CHK_URL, 'w/chkHists');
export const chkHistsBlob = createAxiosWithAuth(CHK_URL, 'w/chkHists', 'blob');
export const chkHistItems = createAxiosWithAuth(CHK_URL, 'w/chkHistItems');
export const chkHistItemsBlob = createAxiosWithAuth(CHK_URL, 'w/chkHistItems', 'blob');
export const checkSheets = createAxiosWithAuth(CHK_URL, 'w/chkSheets');
export const checkSheetItems = createAxiosWithAuth(CHK_URL, 'w/chkSheetItems');
export const checkSheetItemFiles = createAxiosWithAuth(CHK_URL, 'w/chkSheetItemRscFiles');

// create
export const manualProjects = createAxiosWithAuth(CREATE_URL, 'manualProjects');
export const manualTasks = createAxiosWithAuth(CREATE_URL, 'manualTasks');
export const manualTargets = createAxiosWithAuth(CREATE_URL, 'manualTargets');

// 원격지원 통화목록
export const callHistories = createAxiosWithAuth(CMS_URL, 'callHistories');
export const callHistoriesBlob = createAxiosWithAuth(CMS_URL, 'callHistories', 'blob');
