import { factorySensors } from '@/api';

function getFactorySensors(page, pageSize, sortOrder, keyword) {
  return factorySensors.get(`?page=${page}&pageSize=${pageSize}&sortOrder=${sortOrder}&keyword=${keyword}`);
}

function getFactorySensor(sensorId) {
  return factorySensors.get(`${sensorId}`);
}

function postFactorySensor(sensor) {
  return factorySensors.post(``, sensor);
}

function putFactorySensor(sensorId, sensor) {
  return factorySensors.put(`${sensorId}`, sensor);
}

function deleteFactorySensor(sensorId) {
  return factorySensors.delete(`${sensorId}`);
}

function getFactorySensorModel() {
  return factorySensors.get(`modelname`);
}

export {
  getFactorySensors,
  getFactorySensor,
  postFactorySensor,
  putFactorySensor,
  deleteFactorySensor,
  getFactorySensorModel,
};
