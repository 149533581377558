import { checkSheets } from '@/api';

function getRecentCheckSheet(chkGroupId) {
  return checkSheets.get(`?chkGroupId=${chkGroupId}`);
}

function postCheckSheet(sheet) {
  return checkSheets.post(``, sheet);
}

function getCheckSheet(sheetId) {
  return checkSheets.get(`${sheetId}`);
}

function putCheckSheet(sheetId, sheet) {
  return checkSheets.put(`${sheetId}`, sheet);
}

function deleteCheckSheet(sheetId) {
  return checkSheets.delete(`${sheetId}`);
}

function postCheckSheetDuplicate(sheetId, destChkGroupId) {
  return checkSheets.post(`${sheetId}/duplicate`, destChkGroupId);
}

export { getRecentCheckSheet, postCheckSheet, getCheckSheet, putCheckSheet, deleteCheckSheet, postCheckSheetDuplicate };
