<template>
  <transition name="modal">
    <div class="popup-wrapper">
      <div class="popup">
        <div class="popup-header" :class="{ 'bg-none': isNoneHeaderBg, 'border-none': isNoneHeaderBorder }">
          <slot name="title" />
        </div>
        <div class="popup-body">
          <div class="popup-body-contents">
            <slot name="contents" />
          </div>
          <div class="popup-body-buttons" v-if="!isNoneBottomButton">
            <slot name="button" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'GeneralFunctionPopup',
  props: ['isNoneHeaderBg', 'isNoneBottomButton', 'isNoneHeaderBorder'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style scoped lang="scss">
@import 'GeneralFunctionPopup';
</style>
