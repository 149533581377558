import {
  getAllTasks,
  getProjectTasks,
  getTask,
  putTask,
  putTaskCustom,
  putTaskPublish,
  postTask,
  deleteTask,
  duplicateTask,
  moveTask,
  putTaskGuide,
  deleteTaskGuide,
  postTaskContent,
} from '@/api/modules/create/manualTasks';
import getErrorMessage from '@/api/getErrorMessage';

const state = {
  tasks: null,
  isLoadingTasks: false,
  selectedTask: sessionStorage.getItem('selectedTask') ? JSON.parse(sessionStorage.getItem('selectedTask')) : {},
  foundTask: null,
  scenes: {}, // custom - scene
  commonItems: [], // custom - commonItems
  selectedScene: null, // sceneArray에서 선택된 scene
  selectedSceneGroup: null, // sceneArray에서 선택된 sceneGroup
  selectedElement: null,
  storeSceneArray: [],
  isChangeTarget: false,
  activatedType: 'scene', // scene, single, inner, group, asset, check_sheet_asset
};

const getters = {};

const mutations = {
  INIT_TASKS(state) {
    state.tasks = null;
  },
  SET_TASKS(state, payload) {
    state.tasks = payload;
  },
  SET_SELECTED_TASK(state, task) {
    state.selectedTask = task || {};
    sessionStorage.setItem('selectedTask', JSON.stringify(task));
  },
  SET_FOUND_TASK(state, payload) {
    state.foundTask = payload;
  },
  SET_SCENES(state, payload) {
    state.scenes = payload;
  },
  SET_SELECTED_SCENE(state, payload) {
    state.selectedScene = payload;
  },
  SET_SELECTED_SCENE_GROUP(state, payload) {
    state.selectedSceneGroup = payload;
  },
  SET_SELECTED_ELEMENT(state, payload) {
    state.selectedElement = payload;
  },
  SET_COMMON_ITEMS(state, payload) {
    state.commonItems = payload;
  },
  SET_STORE_SCENE_ARRAY(state, payload) {
    state.storeSceneArray = payload;
  },
  SET_IS_CHANGE_TARGET(state, payload) {
    state.isChangeTarget = payload;
  },
  SET_ACTIVATED_TYPE(state, payload) {
    state.activatedType = payload;
  },
};

const actions = {
  async GET_ALL_TASKS({ commit }) {
    try {
      const res = (await getAllTasks()) || { data: [] };
      return res.data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async GET_PROJECT_TASKS({ commit }, projectId) {
    try {
      commit('INIT_TASKS');
      const res = (await getProjectTasks(projectId)) || { data: { result: [] } };
      commit('SET_TASKS', res.data.result);
      return res.data.result;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      commit('SET_TASKS', []);
      return Promise.reject(error);
    }
  },
  async GET_TASK({ commit }, taskId) {
    try {
      const res = (await getTask(taskId)) || { data: null };
      res.data && commit('SET_SELECTED_TASK', res.data);
      return res.data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async PUT_TASK({ commit }, [taskId, task]) {
    try {
      const res = (await putTask(taskId, task)) || { data: null };
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async PUT_TASK_CUSTOM({ commit }, [taskId, customStr, isPublishing]) {
    try {
      const res = (await putTaskCustom(taskId, customStr, isPublishing)) || { data: null };
      res.data && commit('SET_SELECTED_TASK', res.data);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async PUT_TASK_PUBLISH({ commit }, [taskId, isPublishing]) {
    try {
      const res = (await putTaskPublish(taskId, isPublishing)) || { data: null };
      const newTasks = state.tasks.map((task) => {
        if (task.id === taskId) {
          return res.data;
        }
        return task;
      });
      res.data && commit('SET_TASKS', newTasks);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async POST_TASK({ commit }, [projectId, task]) {
    try {
      const res = (await postTask(projectId, task)) || { data: null };
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async DELETE_TASK({ commit }, taskId) {
    try {
      const res = (await deleteTask(taskId)) || { data: null };
      const newTasks = state.tasks
        ? state.tasks.filter((task) => {
            return task.id !== taskId;
          })
        : [];
      res.data && commit('SET_TASKS', newTasks);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async POST_DUPLICATE_TASK_HERE({ commit }, [taskId, projectId]) {
    try {
      const res = (await duplicateTask(taskId, projectId)) || { data: null };
      if (res.data && state.tasks) {
        commit('SET_TASKS', [res.data, ...state.tasks]);
      }
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async POST_DUPLICATE_TASK_MOVE({ commit }, [taskId, projectId]) {
    try {
      const res = (await duplicateTask(taskId, projectId)) || { data: null };
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async PUT_MOVE_TASK({ commit }, [taskId, projectId]) {
    try {
      const res = (await moveTask(taskId, projectId)) || { data: null };
      const newTasks = state.tasks
        ? state.tasks.filter((task) => {
            return task.id !== taskId;
          })
        : [];
      res.data && commit('SET_TASKS', newTasks);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async PUT_TASK_GUIDE({ commit }, [taskId, allFilename, fileBase64]) {
    try {
      const res = (await putTaskGuide(taskId, allFilename, fileBase64)) || { data: null };
      res.data && commit('SET_SELECTED_TASK', res.data);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async DELETE_TASK_GUIDE({ commit }, taskId) {
    try {
      const res = (await deleteTaskGuide(taskId)) || { data: null };
      res.data && commit('SET_SELECTED_TASK', res.data);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('deleteFile', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async POST_TASK_CONTENT({ commit }, [taskId, imgInfo]) {
    try {
      const res = await postTaskContent(taskId, imgInfo);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('uploadFile', error.response ? error.response.status : 500);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
