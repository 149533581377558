import { putActionRequireds, postActionRequireds } from '@/api/modules/checkSheet/actionRequireds';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // PUT : 조치 수정
  async PUT_ACTION_REQUIREDS({ commit }, [id, action]) {
    try {
      const { data } = await putActionRequireds(id, action);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 조치 생성
  async POST_ACTION_REQUIREDS({ commit }, [id, action]) {
    try {
      const { data } = await postActionRequireds(id, action);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
