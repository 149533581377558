import { users } from '@/api';

function postChangeProfile(img) {
  return users.post(`changeProfile/rsc`, img);
}

function putChangeProfile(img) {
  return users.put(`changeProfile`, img);
}

function getResetPassword(loginId) {
  return users.get(`resetPassword?loginId=${loginId}`);
}

function putResetPassword(pwData) {
  return users.put(`resetPassword`, pwData);
}

function getMyInfo() {
  return users.get(`myInfo`);
}

function putMyInfo(myInfo) {
  return users.put(`myInfo`, myInfo);
}

function putChangePassword(pwData) {
  return users.put(`changePassword`, pwData);
}

function getDuplicateCheck(loginId, type) {
  return users.get(`duplicateCheck?loginId=${loginId}&companyType=${type}`);
}

function getQrCode(value) {
  return users.get(`getQrCode?qrValue=${value}`);
}

function getReissueQrCode(loginId) {
  return users.get(`reissue/qrcode?loginId=${loginId}`);
}

export {
  postChangeProfile,
  putChangeProfile,
  getResetPassword,
  putResetPassword,
  getMyInfo,
  putMyInfo,
  putChangePassword,
  getDuplicateCheck,
  getQrCode,
  getReissueQrCode,
};
