import { actionRequireds } from '@/api';

function putActionRequireds(id, action) {
  return actionRequireds.put(`${id}`, action);
}

function postActionRequireds(id, action) {
  return actionRequireds.post(`${id}/action`, action);
}

export { putActionRequireds, postActionRequireds };
