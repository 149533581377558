import { manufacturerEqpGroups } from '@/api';

function getManufacturerEqpGroups(sortOrder, keyword) {
  return manufacturerEqpGroups.get(`?sortOrder=${sortOrder}&keyword=${keyword}`);
}

function getManufacturerEqpGroup(eqpGroupId) {
  return manufacturerEqpGroups.get(`${eqpGroupId}`);
}

function postManufacturerEqpGroup(eqpGroup) {
  return manufacturerEqpGroups.post(``, eqpGroup);
}

function putManufacturerEqpGroup(eqpGroupId, eqpGroup) {
  return manufacturerEqpGroups.put(`${eqpGroupId}`, eqpGroup);
}

function deleteManufacturerEqpGroup(eqpGroupId) {
  return manufacturerEqpGroups.delete(`${eqpGroupId}`);
}

function postManufacturerEqpGroupRsc(rsc) {
  return manufacturerEqpGroups.post(`rsc`, rsc);
}

function deleteManufacturerEqpGroupRsc(eqpGroupId) {
  return manufacturerEqpGroups.delete(`rsc/${eqpGroupId}`);
}

export {
  putManufacturerEqpGroup,
  getManufacturerEqpGroups,
  postManufacturerEqpGroup,
  getManufacturerEqpGroup,
  deleteManufacturerEqpGroup,
  deleteManufacturerEqpGroupRsc,
  postManufacturerEqpGroupRsc,
};
