import authStore from '@/store/modules/common/auth';
import { requestToken } from '@/api/modules/common/auth';

export function setInterceptors(axiosService) {
  axiosService.interceptors.request.use(
    async (config) => {
      // 요청을 보내기 전에 어떤 처리를 할 수 있다.
      config.headers['X-Auth-Msf-Token'] = authStore.state.token;
      return config;
    },
    (error) => {
      // 요청이 잘못되었을 때 에러가 컴포넌트 단으로 오기 전에 어떤 처리를 할 수 있다.
      return Promise.reject(error);
    },
  );

  axiosService.interceptors.response.use(
    (response) => {
      // 서버에 요청을 보내고 나서 응답을 받기 전에 어떤 처리를 할 수 있다.
      return response;
    },
    async (error) => {
      // 응답이 에러인 경우에 미리 전처리할 수 있다.
      const {
        config,
        response: { status },
      } = error;
      if ([401].includes(status)) {
        try {
          const { data } = await requestToken({ rfToken: authStore.state.rfToken });
          authStore.mutations.SET_TOKEN(authStore.state, data.token);
          authStore.mutations.SET_REFRESH_TOKEN(authStore.state, data.rfToken);
          config.headers['X-Auth-Msf-Token'] = authStore.state.token;
          return axiosService(config);
        } catch (error) {
          console.error(error);
        }
      }
      return Promise.reject(error);
    },
  );
  return axiosService;
}
