import { manualProjects } from '@/api';

function getMyProjects() {
  return manualProjects.get(`my`);
}

function getSharedProjects() {
  return manualProjects.get(`shared`);
}

function getProject(projectId) {
  return manualProjects.get(`${projectId}`);
}

function postProject(projectName) {
  return manualProjects.post('', projectName);
}

function putProject(projectId, projectName) {
  return manualProjects.put(`${projectId}`, projectName);
}

function deleteProject(projectId) {
  return manualProjects.delete(`${projectId}`);
}

export { getMyProjects, getSharedProjects, getProject, postProject, putProject, deleteProject };
