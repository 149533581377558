<template>
  <general-function-popup v-if="isShow" :is-none-header-bg="true" :is-none-header-border="true">
    <h2 slot="title">비밀번호 변경</h2>
    <div slot="contents">
      <input-box
        type="password"
        id="old-password"
        label="현재 비밀번호 <span class='color-primary400'>*</span>"
        v-model="oldPassword"
        autocomplete="off"
        :error-message="oldPwErrorMessage"
        tabindex="1"
        defaultMessage=" "
        class="mb-10"
        @input="onCheckInputValue"
        maxlength="16"
        is-height-large
      />
      <input-box
        type="password"
        id="new-password"
        label="새 비밀번호 <span class='color-primary400'>*</span>"
        v-model="newPassword"
        autocomplete="off"
        :error-message="newPwErrorMessage"
        tabindex="2"
        defaultMessage=" "
        class="mb-10"
        @input="onCheckInputValue"
        maxlength="16"
        is-height-large
      />
      <input-box
        type="password"
        id="re-new-password"
        label="새 비밀번호 확인 <span class='color-primary400'>*</span>"
        v-model="reNewPassword"
        autocomplete="off"
        tabindex="3"
        :error-message="reNewPwErrorMessage"
        class="mb-10"
        defaultMessage=" "
        @input="onCheckInputValue"
        maxlength="16"
        is-height-large
      />
      <p class="info-text" :class="{ 'error-text': !isValidPassword }">
        <vue-material-icon name="info" :size="16" />
        {{ $t('message.pw_condition_msg') }}
      </p>
    </div>
    <button-basic slot="button" color="gray-border-1" @click="onClosePopup">
      {{ $t('message.cancel_btn') }}
    </button-basic>
    <button-basic
      slot="button"
      color="dark"
      @click="onClickChangePassword"
      :disabled="!oldPassword || !newPassword || !reNewPassword"
      :loading-dot="isLoadingButton"
    >
      변경
    </button-basic>
  </general-function-popup>
</template>

<script>
import { mapActions } from 'vuex';
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import InputBox from '@/component/input/InputBox';
import validPassword from '@/asset/js/validPassword';

export default {
  name: 'ChangePasswordPopup',
  props: ['isShow'],
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
      oldPwErrorMessage: '',
      newPwErrorMessage: '',
      reNewPwErrorMessage: '',
      isLoadingButton: false,
      isValidPassword: true,
      showedPopupName: '',
      popupContentsKey: '',
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('users', ['PUT_CHANGE_PASSWORD']),
    initPasswordError() {
      this.oldPwErrorMessage = '';
      this.newPwErrorMessage = '';
      this.reNewPwErrorMessage = '';
      this.isValidPassword = true;
    },
    checkPasswordError() {
      let isError = false;

      if (this.newPassword !== this.reNewPassword) {
        this.reNewPwErrorMessage = this.$i18n.messages[this.$lang].message.pw_error_2_msg;
        this.newPwErrorMessage = ' ';
        isError = true;
      } else if (this.oldPassword === this.newPassword) {
        this.newPwErrorMessage = this.$i18n.messages[this.$lang].message.pw_error_3_msg;
        this.reNewPwErrorMessage = ' ';
        isError = true;
      }
      if (!validPassword(this.newPassword)) {
        this.isValidPassword = false;
        this.newPwErrorMessage = ' ';
        this.reNewPwErrorMessage = ' ';
        isError = true;
      }
      if (!isError) {
        this.changePassword();
      }
    },
    async changePassword() {
      try {
        this.isLoadingButton = true;
        await this.PUT_CHANGE_PASSWORD({
          password: this.oldPassword,
          changePassword: this.newPassword,
        });
        this.$emit('close');
      } catch (error) {
        if (error.response.status === 403) {
          this.oldPwErrorMessage = this.$i18n.messages[this.$lang].message.pw_error_1_msg;
        }
      } finally {
        this.isLoadingButton = false;
      }
    },
    onClickChangePassword() {
      this.initPasswordError();
      this.checkPasswordError();
    },
    onClosePopup() {
      this.$emit('close');
      this.initPasswordError();
      this.oldPassword = '';
      this.newPassword = '';
      this.reNewPassword = '';
    },
    onCheckInputValue(value) {
      if (!value) {
        this.initPasswordError();
      }
    },
  },
  components: { GeneralFunctionPopup, InputBox },
};
</script>

<style scoped lang="scss">
@import 'ChangePasswordPopup';
</style>
