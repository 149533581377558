import {
  getRecentCheckSheet,
  postCheckSheet,
  getCheckSheet,
  putCheckSheet,
  deleteCheckSheet,
  postCheckSheetDuplicate,
} from '@/api/modules/checkSheet/checkSheets';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 가장 최신 점검 시트 요청
  async GET_RECENT_CHECK_SHEET({ commit }, chkGroupId) {
    try {
      const { data } = await getRecentCheckSheet(chkGroupId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 점검 시트 요청
  async GET_CHECK_SHEET({ commit }, sheetId) {
    try {
      const { data } = await getCheckSheet(sheetId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 점검 시트 저장 요청
  async POST_CHECK_SHEET({ commit }, sheet) {
    try {
      const { data } = await postCheckSheet(sheet);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 점검 시트 복제 요청
  async POST_CHECK_SHEET_DUPLICATE({ commit }, [sheetId, destChkGroupId]) {
    try {
      const { data } = await postCheckSheetDuplicate(sheetId, destChkGroupId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 점검 시트 수정 요청
  async PUT_CHECK_SHEET({ commit }, [sheetId, sheet]) {
    try {
      const { data } = await putCheckSheet(sheetId, sheet);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 점검 시트 삭제 요청
  async DELETE_CHECK_SHEET({ commit }, sheetId) {
    try {
      const { data } = await deleteCheckSheet(sheetId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
