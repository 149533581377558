import {
  getMyProjects,
  getSharedProjects,
  getProject,
  postProject,
  putProject,
  deleteProject,
} from '@/api/modules/create/manualProjects';
import getErrorMessage from '@/api/getErrorMessage';

const state = {
  myProjects: [],
  sharedProjects: [],
  selectedProject: JSON.parse(sessionStorage.getItem('selectedProject')) || {}, // 선택된 project
};

const getters = {
  isExistMyProject(state) {
    return state.myProjects.length !== 0;
  },
};

const mutations = {
  SET_MY_PROJECTS(state, projects) {
    state.myProjects = projects;
  },
  SET_SHARED_PROJECTS(state, projects) {
    state.sharedProjects = projects;
  },
  SET_SELECTED_PROJECT(state, project) {
    state.selectedProject = project;
    sessionStorage.setItem('selectedProject', JSON.stringify(project));
  },
  SET_SELECTED_PROJECT_BY_ID(state, pId) {
    const projects = [...state.myProjects, ...state.sharedProjects];
    const project = projects.find((project) => {
      return project.id === pId;
    });
    if (project) {
      state.selectedProject = project;
      sessionStorage.setItem('selectedProject', JSON.stringify(project));
    }
  },
};

const actions = {
  async GET_MY_PROJECTS({ commit }) {
    try {
      const res = (await getMyProjects()) || { data: [] };
      commit('SET_MY_PROJECTS', res.data);
      return res.data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async GET_SHARED_PROJECTS({ commit }) {
    try {
      const res = (await getSharedProjects()) || { data: [] };
      commit('SET_SHARED_PROJECTS', res.data);
      return res.data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async GET_PROJECT({ commit }, projectId) {
    try {
      const res = (await getProject(projectId)) || { data: null };
      res.data && commit('SET_SELECTED_PROJECT', res.data);
      return res.data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async POST_PROJECT({ commit }, projectName) {
    try {
      const res = (await postProject(projectName)) || { data: null };
      res.data && commit('SET_MY_PROJECTS', [res.data, ...state.myProjects]);
      res.data && commit('SET_SELECTED_PROJECT', res.data);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async PUT_PROJECT({ commit }, [projectId, projectName]) {
    try {
      const res = (await putProject(projectId, projectName)) || { data: null };
      const newMyProjects = state.myProjects.map((project) => {
        if (project.id === projectId) {
          return res.data;
        }
        return project;
      });
      res.data && commit('SET_MY_PROJECTS', newMyProjects);
      res.data && commit('SET_SELECTED_PROJECT', res.data);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  async DELETE_PROJECT({ commit }, projectId) {
    try {
      const res = (await deleteProject(projectId)) || { data: null };
      const newMyProjects = state.myProjects.filter((project) => {
        return project.id !== projectId;
      });
      res.data && commit('SET_MY_PROJECTS', newMyProjects);
      return res.data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
