import { chkHists, cmsChkHists, chkHistsBlob } from '@/api';

function getCheckHistories(startDT, endDT) {
  return chkHists.get(`?startDT=${startDT}&endDT=${endDT}`);
}

function getCheckHistoryExcel(startDT, endDT) {
  return chkHistsBlob.get(`excel/download?startDT=${startDT}&endDT=${endDT}`);
}

function getCheckHistoryCalendar(startDT, endDT, keyword) {
  return cmsChkHists.get(`calendar?startDay=${startDT}&endDay=${endDT}&keyword=${keyword}`);
}

function getActionRequireCalendar(startDT, endDT, keyword) {
  return cmsChkHists.get(`actionRequireds/calendar?startDay=${startDT}&endDay=${endDT}&keyword=${keyword}`);
}

function getActionRequireDetail(startDT, endDT) {
  return cmsChkHists.get(`actionRequireds/detail?startDay=${startDT}&endDay=${endDT}`);
}

export {
  getCheckHistories,
  getCheckHistoryExcel,
  getCheckHistoryCalendar,
  getActionRequireCalendar,
  getActionRequireDetail,
};
