var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "general-function-popup",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      attrs: { "is-none-header-bg": true, "is-none-header-border": true },
    },
    [
      _c("h2", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("프로필 변경"),
      ]),
      _c("div", { attrs: { slot: "contents" }, slot: "contents" }, [
        _c(
          "div",
          { staticClass: "photo-wrapper" },
          [
            _c("div", { staticClass: "profile-image-wrapper" }, [
              !_vm.imageUrl
                ? _c("img", {
                    attrs: {
                      src: "/images/profile_avatar.svg",
                      alt: "Profile Image",
                    },
                  })
                : _vm._e(),
              _vm.imageUrl
                ? _c("img", {
                    staticClass: "profile-image",
                    attrs: {
                      src: _vm.imageUrl,
                      id: "preview",
                      alt: "Profile Image",
                    },
                  })
                : _vm._e(),
            ]),
            _c(
              "file-basic",
              {
                attrs: {
                  color: "lightgray",
                  circle: 40,
                  accept: ".jpg, .png",
                  id: "upFileProfile",
                },
                on: { change: _vm.onSelectFile },
              },
              [_c("camera-icon")],
              1
            ),
          ],
          1
        ),
        _c(
          "ul",
          { staticClass: "profile-icons" },
          _vm._l(_vm.profileIcons, function (iconSrc, index) {
            return _c(
              "li",
              {
                key: index,
                class: {
                  selected: _vm.getIsSelectedIcon(iconSrc, index),
                },
                on: {
                  click: function ($event) {
                    return _vm.onSelectProfileIcon($event, iconSrc, index)
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: iconSrc, alt: "Profile Icon Sample" },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "button-basic",
        {
          attrs: { slot: "button", color: "gray-border-1" },
          on: { click: _vm.onClosePopup },
          slot: "button",
        },
        [_vm._v(" " + _vm._s(_vm.$t("message.cancel_btn")) + " ")]
      ),
      _c(
        "button-basic",
        {
          attrs: {
            slot: "button",
            color: "dark",
            "loading-dot": _vm.isLoadingButton,
            disabled: !_vm.imageUrl || !_vm.isChangeImage,
          },
          on: { click: _vm.onSaveProfileImage },
          slot: "button",
        },
        [_vm._v(" 변경 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }