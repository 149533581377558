<template>
  <footer>
    <div>
      <img src="/images/maxst_logo.svg" alt="MAXST logo" class="maxst-logo mr-10" />
      <p class="copyright">© 2021 MAXST Co., Ltd. All Rights Reserved.</p>
    </div>
    <ul class="policy-list">
      <li class="mr-20">
        <router-link to="/privacy-policy">개인정보처리방침</router-link>
      </li>
      <li>
        <router-link to="/terms-of-use">서비스이용약관</router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
@import 'Footer';
</style>
