import moment from 'moment';

export function localDateYYYYMMDD(date) {
  return date ? moment.utc(date).local().format('YYYY-MM-DD') : '-';
}
export function localDateYYYYMMDDTime(date) {
  return date ? moment.utc(date).local().format('YYYY-MM-DD HH:mm') : '-';
}
export function localDateYYYYMMDDTimeSecond(date) {
  return date ? moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss') : '-';
}
export function localDateYYYYMM(date) {
  return date ? moment.utc(date).local().format('YYYY-MM') : '-';
}

export function formatSecondsToFullTime(sec_num) {
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10 && hours > 0) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${hours ? `${hours}:` : ''}${minutes}:${seconds}`;
}

export function formatEQPCode({ mEqpId, eqpId, type = mEqpId ? 'M' : 'F' }) {
  const code = String(mEqpId || eqpId).padStart(6, '0');
  return `${type}E${code}`;
}

export function formatSensorCode({ sensorId, type = '' }) {
  const code = String(sensorId).padStart(6, '0');
  return `${type}S${code}`;
}

export function roleLabel(value) {
  switch (value) {
    case 'SUPERVISOR':
      return '슈퍼바이저';
    case 'STAFF':
      return '스태프';
    case 'MANAGER':
      return '매니저';
    case 'MEMBER':
      return '멤버';
  }
}
