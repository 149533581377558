import { actionRscFiles } from '@/api';

function postActionRscFiles(rscFile) {
  return actionRscFiles.post(``, rscFile);
}

function deleteActionRscFiles(id) {
  return actionRscFiles.delete(`${id}`);
}

export { postActionRscFiles, deleteActionRscFiles };
