<template>
  <div class="input-label-wrapper" :class="{ 'input-flex': isFlex }">
    <label :for="id" v-html="label" />
    <div
      class="input-wrapper"
      @click="$emit('click')"
      :class="{
        'error-border': errorMessage,
        'exist-value': isFocused || value,
        disabled,
        'height-large': isHeightLarge,
      }"
    >
      <span v-if="name" class="input-left-icon" data-icon="input-left-icon">
        <vue-material-icon :name="name" />
      </span>
      <input
        :type="type"
        :id="id"
        :value="typeof value === 'string' ? value.trim() : value"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :maxlength="maxlength"
        :tabindex="tabindex"
        @input="onChange"
        @keyup.enter="onKeyUpEnter"
        @focus="toggleInputFocused(true)"
        @blur="toggleInputFocused(false)"
        :readonly="isReadonly"
      />
      <button v-if="type === 'password'" class="visibility-btn" @click="onChangeVisibility($event, id)">
        <span data-icon="visibility_off">
          <vue-material-icon name="visibility_off" />
        </span>
        <span class="hide" data-icon="visibility">
          <vue-material-icon name="visibility" class="visibility" />
        </span>
      </button>
    </div>
    <p class="info-text" :class="{ 'error-text': errorMessage }" v-if="errorMessage || defaultMessage">
      <vue-material-icon v-if="iconY" name="error" />
      {{ !errorMessage ? defaultMessage : errorMessage }}
    </p>
    <p class="text-count" v-if="textCount">{{ value.length }}/{{ maxlength }}</p>
  </div>
</template>

<script>
import visiblePassword from '@/asset/js/visiblePassword';

export default {
  name: 'InputBox',
  data() {
    return {
      isFocused: false,
      isReadonly: true,
    };
  },
  props: {
    name: {
      type: String,
      require: false,
      default: '',
    },
    type: {
      type: String,
      require: false,
      default: 'text',
    },
    id: {
      type: String,
      require: false,
      default: '',
    },
    value: {
      type: [String, Number],
      require: false,
      default: '',
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    autocomplete: {
      type: String,
      require: false,
      default: '',
    },
    errorMessage: {
      type: String,
      require: false,
      default: '',
    },
    iconY: {
      type: String,
      require: false,
      default: '',
    },
    maxlength: {
      type: Number,
      required: false,
    },
    label: {
      type: String,
      require: false,
      default: '',
    },
    defaultMessage: {
      type: String,
      require: false,
      default: '',
    },
    tabindex: {
      type: String,
      require: false,
      default: '',
    },
    isFlex: {
      type: Boolean,
      required: false,
      default: false,
    },
    textCount: {
      type: Number,
      required: false,
    },
    isHeightLarge: {
      type: Boolean,
      required: false,
    },
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    toggleInputFocused(isFocused) {
      this.isFocused = isFocused;
      this.isReadonly = !isFocused;
    },
    onKeyUpEnter() {
      this.$emit('keyupenter');
    },
    onChange(event) {
      if (this.id === 'phone' || this.id.includes('alert-code-')) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
      }
      this.$emit('input', event.target.value.trim());
    },
    onChangeVisibility(e, pw) {
      visiblePassword(e, pw);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'InputBox';
</style>
