const errorCode = {
  common: {
    400: 'error_popup',
    401: 'session_popup',
    403: 'authority_popup',
    500: 'error_popup',
  },
  uploadFile: {
    400: 'file_upload_error_popup',
    401: 'session_popup',
    403: 'authority_popup',
    500: 'file_upload_error_popup',
  },
  deleteFile: {
    400: 'file_delete_error_popup',
    401: 'session_popup',
    403: 'authority_popup',
    500: 'file_delete_error_popup',
  },
};

const getErrorMessage = (type, code) => {
  if (code === 401) {
    return {
      title: '',
      description: errorCode[type][code] || 'error_popup',
      isLogout: true,
    };
  }
  return errorCode[type][code] || 'error_popup';
};

export default getErrorMessage;
