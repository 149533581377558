import {
  getCallHistories,
  getCallHistoryContents,
  getDownloadCallHistoryContent,
  deleteCallHistoryContent,
} from '@/api/modules/callHistory/callHistories';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 통화 목록 조회
  async GET_CALL_HISTORIES({ commit }, [keyword, startDT, endDT, page, pageSize, companyType]) {
    try {
      const { data } = await getCallHistories(keyword, startDT, endDT, page, pageSize, companyType);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 통화의 영상 목록 조회
  async GET_CALL_HISTORY_CONTENTS({ commit }, callId) {
    try {
      const { data } = await getCallHistoryContents(callId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 특정 영상 다운로드
  async GET_DOWNLOAD_CALL_HISTORY_CONTENT({ commit }, [callId, contentId]) {
    try {
      return await getDownloadCallHistoryContent(callId, contentId);
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 특정 영상 삭제
  async DELETE__CALL_HISTORY_CONTENT({ commit }, contentId) {
    try {
      const { data } = await deleteCallHistoryContent(contentId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
