import Vue from 'vue';
import Vuex from 'vuex';
import globalError from '@/store/modules/globalError';
import chkHists from '@/store/modules/checkSheet/chkHists';
import chkHistItems from '@/store/modules/checkSheet/chkHistItems';
import checkSheets from '@/store/modules/checkSheet/checkSheets';
import checkSheetItems from '@/store/modules/checkSheet/checkSheetItems';
import checkSheetItemFiles from '@/store/modules/checkSheet/checkSheetItemFiles';
import actionRequireds from '@/store/modules/checkSheet/actionRequireds';
import actionRscFiles from '@/store/modules/checkSheet/actionRscFiles';
import adminConfigs from '@/store/modules/common/adminConfigs';
import auth from '@/store/modules/common/auth';
import users from '@/store/modules/common/users';
import manualProjects from '@/store/modules/create/manualProjects';
import manualTargets from '@/store/modules/create/manualTargets';
import manualTasks from '@/store/modules/create/manualTasks';
import factoryAlert from '@/store/modules/factory/alert';
import factoryEqpGroups from '@/store/modules/factory/eqpGroups';
import factoryEquipments from '@/store/modules/factory/equipments';
import factorySelfEquipments from '@/store/modules/factory/selfEquipments';
import factoryUsers from '@/store/modules/factory/users';
import factorySensors from '@/store/modules/factory/sensors';
import manufacturerAlert from '@/store/modules/manufacturer/alert';
import manufacturerEqpGroups from '@/store/modules/manufacturer/eqpGroups';
import manufacturerEquipments from '@/store/modules/manufacturer/equipments';
import manufacturerUsers from '@/store/modules/manufacturer/users';
import manufacturerSensors from '@/store/modules/manufacturer/sensors';
import callHistories from '@/store/modules/callHistory/callHistories';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    globalError,
    chkHists,
    chkHistItems,
    checkSheets,
    checkSheetItems,
    checkSheetItemFiles,
    actionRequireds,
    actionRscFiles,
    adminConfigs,
    auth,
    users,
    manualProjects,
    manualTargets,
    manualTasks,
    factoryAlert,
    factoryEqpGroups,
    factoryEquipments,
    factorySelfEquipments,
    factoryUsers,
    factorySensors,
    manufacturerAlert,
    manufacturerEqpGroups,
    manufacturerEquipments,
    manufacturerUsers,
    manufacturerSensors,
    callHistories,
  },
});
