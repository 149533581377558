var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "popup-wrapper" }, [
      _c("div", { staticClass: "popup" }, [
        _c(
          "div",
          {
            staticClass: "popup-header",
            class: {
              "bg-none": _vm.isNoneHeaderBg,
              "border-none": _vm.isNoneHeaderBorder,
            },
          },
          [_vm._t("title")],
          2
        ),
        _c("div", { staticClass: "popup-body" }, [
          _c(
            "div",
            { staticClass: "popup-body-contents" },
            [_vm._t("contents")],
            2
          ),
          !_vm.isNoneBottomButton
            ? _c(
                "div",
                { staticClass: "popup-body-buttons" },
                [_vm._t("button")],
                2
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }