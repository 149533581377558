var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "header-dropdown user-menu-list" }, [
    _c("li", { staticClass: "text-overflow-ellipsis" }, [
      _vm._v(_vm._s(_vm.myInfo.companyName)),
    ]),
    _c("li", [
      _c(
        "span",
        {
          staticClass: "text-overflow-ellipsis",
          class: { "user-name-width": _vm.hasMultiManagerRoles },
        },
        [_vm._v(" " + _vm._s(_vm.myInfo.userName) + " ")]
      ),
      _vm.hasMultiManagerRoles
        ? _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("togglePopup", "RoleChangePopup")
                },
              },
            },
            [_vm._v("계정 역할 변경")]
          )
        : _vm._e(),
    ]),
    _c("li", [
      _c("span", [_vm._v(_vm._s(_vm.role))]),
      _c("span", { staticClass: "text-overflow-ellipsis" }, [
        _vm._v(_vm._s(_vm.myInfo.phone)),
      ]),
      _c("span", { staticClass: "text-overflow-ellipsis" }, [
        _vm._v(_vm._s(_vm.myInfo.loginId)),
      ]),
    ]),
    _c(
      "li",
      {
        staticClass: "mt-5",
        on: {
          click: function ($event) {
            return _vm.$emit("togglePopup", "ProfileImagePopup")
          },
        },
      },
      [
        _c(
          "span",
          { attrs: { "data-icon": "person" } },
          [_c("vue-material-icon", { attrs: { name: "person", size: 12 } })],
          1
        ),
        _c("span", [_vm._v("프로필 변경")]),
      ]
    ),
    _c(
      "li",
      {
        on: {
          click: function ($event) {
            return _vm.$emit("togglePopup", "ChangePhonePopup")
          },
        },
      },
      [
        _c(
          "span",
          { attrs: { "data-icon": "phone_iphone" } },
          [
            _c("vue-material-icon", {
              attrs: { name: "phone_iphone", size: 12 },
            }),
          ],
          1
        ),
        _c("span", [_vm._v("연락처 변경")]),
      ]
    ),
    _c(
      "li",
      {
        staticClass: "mb-5",
        on: {
          click: function ($event) {
            return _vm.$emit("togglePopup", "ChangePasswordPopup")
          },
        },
      },
      [
        _c(
          "span",
          { attrs: { "data-icon": "vpn_key" } },
          [_c("vue-material-icon", { attrs: { name: "vpn_key", size: 12 } })],
          1
        ),
        _c("span", [_vm._v("비밀번호 변경")]),
      ]
    ),
    _c(
      "li",
      {
        staticClass: "mt-5",
        on: {
          click: function ($event) {
            return _vm.onMovePage("terms-of-use")
          },
        },
      },
      [_vm._v("서비스이용약관")]
    ),
    _c(
      "li",
      {
        on: {
          click: function ($event) {
            return _vm.onMovePage("privacy-policy")
          },
        },
      },
      [_vm._v("개인정보처리방침")]
    ),
    _c(
      "li",
      {
        on: {
          click: function ($event) {
            return _vm.$emit("togglePopup", "ConfirmPopup", "LOGOUT")
          },
        },
      },
      [_vm._m(0), _c("span", [_vm._v("로그아웃")])]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/images/logout.svg", alt: "logout" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }