import {
  getFactorySensors,
  getFactorySensor,
  postFactorySensor,
  deleteFactorySensor,
  putFactorySensor,
  getFactorySensorModel,
} from '@/api/modules/factory/sensors';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 도입 회사 센서 목록 조회
  async GET_FACTORY_SENSORS({ commit }, [page, pageSize, sortOrder, keyword]) {
    try {
      const { data } = await getFactorySensors(page, pageSize, sortOrder, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 도입 회사 센서 상세
  async GET_FACTORY_SENSOR({ commit }, sensorId) {
    try {
      const { data } = await getFactorySensor(sensorId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 도입 회사 센서 등록
  async POST_FACTORY_SENSOR({ commit }, sensor) {
    try {
      const { data } = await postFactorySensor(sensor);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 도입 회사 센서 수정
  async PUT_FACTORY_SENSOR({ commit }, [sensorId, sensor]) {
    try {
      const { data } = await putFactorySensor(sensorId, sensor);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 도입 회사 센서 삭제
  async DELETE_FACTORY_SENSOR({ commit }, sensorId) {
    try {
      const { data } = await deleteFactorySensor(sensorId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 센서 모델명 목록 조회 (드롭다운)
  async GET_FACTORY_SENSOR_MODEL({ commit }) {
    try {
      const { data } = await getFactorySensorModel();
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
