var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "window-os": _vm.isWindowOS }, attrs: { id: "app" } },
    [
      _vm.hasPageHeader ? _c("header-component") : _vm._e(),
      _vm.hasPageSidebar ? _c("sidebar-component") : _vm._e(),
      _c("router-view"),
      _vm.hasPageFooter ? _c("footer-component") : _vm._e(),
      _c("error-popup", {
        attrs: { "is-show": _vm.isShowErrorPopup, data: _vm.popupData },
        on: {
          close: function ($event) {
            return _vm.onCloseErrorPopup(_vm.popupData)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }