import { checkSheetItems } from '@/api';

function getCheckSheetItems(sheetId) {
  return checkSheetItems.get(`?chkSheetId=${sheetId}`);
}

function getCheckSheetItem(sheetItemId) {
  return checkSheetItems.get(`${sheetItemId}`);
}

function postCheckSheetItems(sheetItems) {
  return checkSheetItems.post(`save`, sheetItems);
}

export { getCheckSheetItems, getCheckSheetItem, postCheckSheetItems };
