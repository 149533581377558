import { getCheckSheetItems, getCheckSheetItem, postCheckSheetItems } from '@/api/modules/checkSheet/checkSheetItems';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 점검 시트의 항목 목록 요청
  async GET_CHECK_SHEET_ITEMS({ commit }, sheetId) {
    try {
      const { data } = await getCheckSheetItems(sheetId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 개별 점검 항목 요청
  async GET_CHECK_SHEET_ITEM({ commit }, sheetItemId) {
    try {
      const { data } = await getCheckSheetItem(sheetItemId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 점검 시트 항목들 저장 요청
  async POST_CHECK_SHEET_ITEMS({ commit }, sheetItems) {
    try {
      const { data } = await postCheckSheetItems(sheetItems);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
