var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "general-simple-popup",
        [
          _vm.confirmPopupData[_vm.contentsKey].title
            ? _c("h2", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "message." + _vm.confirmPopupData[_vm.contentsKey].title
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("p", {
            attrs: { slot: "contents" },
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "message." +
                    _vm.confirmPopupData[_vm.contentsKey].description,
                  _vm.namedFormatMsg
                )
              ),
            },
            slot: "contents",
          }),
          !_vm.confirmPopupData[_vm.contentsKey]["isAlertType"]
            ? _c(
                "button-basic",
                {
                  attrs: { slot: "button", color: "gray-border-1" },
                  on: { click: _vm.onClose },
                  slot: "button",
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.cancel_btn")) + " ")]
              )
            : _vm._e(),
          _c(
            "button-basic",
            {
              attrs: {
                slot: "button",
                color:
                  _vm.confirmPopupData[_vm.contentsKey]["confirmButtonColor"] ||
                  "dark",
                "loading-dot": _vm.isLoading,
              },
              on: { click: _vm.onConfirm },
              slot: "button",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "message." +
                        (_vm.confirmPopupData[_vm.contentsKey][
                          "confirmButton"
                        ] || "confirm_btn")
                    )
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }