var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "general-simple-popup",
        [
          _c("h2", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("계정 역할 변경"),
          ]),
          _c(
            "div",
            {
              staticClass: "color-gray700",
              attrs: { slot: "contents" },
              slot: "contents",
            },
            [
              _c("p", [
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.myInfo.companyName) +
                      " " +
                      _vm._s(_vm.myInfo.userName) +
                      "(" +
                      _vm._s(_vm.myInfo.loginId) +
                      ")"
                  ),
                ]),
                _vm._v(" 님 "),
              ]),
              _c("p", [
                _vm._v(
                  " 현재 " +
                    _vm._s(_vm.currentCompanyName) +
                    " " +
                    _vm._s(_vm.currentRole) +
                    "로 MSF를 사용 중입니다. "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.changeCompany) +
                    " " +
                    _vm._s(_vm.changeRole) +
                    "로 변경하시겠습니까? "
                ),
              ]),
            ]
          ),
          _c(
            "button-basic",
            {
              attrs: { slot: "button", color: "gray-border-1" },
              on: { click: _vm.onClosePopup },
              slot: "button",
            },
            [_vm._v("취소")]
          ),
          _c(
            "button-basic",
            {
              attrs: { slot: "button", color: "primary" },
              on: { click: _vm.onChangeRoleAndMovePage },
              slot: "button",
            },
            [_vm._v(" " + _vm._s(_vm.$t("message.confirm_btn")) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }