<template>
  <general-simple-popup v-if="isShow">
    <h2 slot="title" v-if="confirmPopupData[contentsKey].title">
      {{ $t(`message.${confirmPopupData[contentsKey].title}`) }}
    </h2>
    <p slot="contents" v-html="$t(`message.${confirmPopupData[contentsKey].description}`, namedFormatMsg)" />
    <button-basic
      v-if="!confirmPopupData[contentsKey]['isAlertType']"
      slot="button"
      color="gray-border-1"
      @click="onClose"
    >
      {{ $t('message.cancel_btn') }}
    </button-basic>
    <button-basic
      slot="button"
      :color="confirmPopupData[contentsKey]['confirmButtonColor'] || 'dark'"
      @click="onConfirm"
      :loading-dot="isLoading"
    >
      {{ $t(`message.${confirmPopupData[contentsKey]['confirmButton'] || 'confirm_btn'}`) }}
    </button-basic>
  </general-simple-popup>
</template>
<script>
import confirmPopupData from './ConfirmPopupData.json';
import GeneralSimplePopup from '@/component/popup/generalSimplePopup/GeneralSimplePopup';

export default {
  name: 'ConfirmPopup',
  props: ['isShow', 'contentsKey', 'i18nNamedFormat', 'isLoading'],
  data() {
    return {
      confirmPopupData: confirmPopupData,
    };
  },
  computed: {
    namedFormatMsg() {
      return this.i18nNamedFormat ? { msg: this.i18nNamedFormat } : null;
    },
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      if (this.confirmPopupData[this.contentsKey]['isAlertType']) {
        this.onClose();
      } else {
        this.$emit('confirm');
      }
    },
  },
  components: { GeneralSimplePopup },
};
</script>
