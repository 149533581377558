import { chkHistItems, chkHistItemsBlob } from '@/api';

function getCheckHistoryItems(chkHistId) {
  return chkHistItems.get(`?chkHistId=${chkHistId}`);
}

function getCheckHistoryItemsByEqpId(chkObjectId, startDT, endDT, keyword, filter, sortOrder, page, pageSize) {
  return chkHistItems.get(
    `${chkObjectId}?startDT=${startDT}&endDT=${endDT}&keyword=${keyword}&filter=${filter}&sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}`,
  );
}

function getCheckHistoryItemsExcel(chkObjectId, startDT, endDT, keyword, filter, sortOrder, equipmentName) {
  return chkHistItemsBlob.get(
    `${chkObjectId}/excel/download?startDT=${startDT}&endDT=${endDT}&keyword=${keyword}&filter=${filter}&sortOrder=${sortOrder}&equipmentName=${equipmentName}`,
  );
}

function getCheckHistoryItemsCalendar(chkObjectId, startDT, endDT) {
  return chkHistItems.get(`${chkObjectId}/calendar?startDay=${startDT}&endDay=${endDT}`);
}

export { getCheckHistoryItems, getCheckHistoryItemsByEqpId, getCheckHistoryItemsExcel, getCheckHistoryItemsCalendar };
