import { factoryEqpGroupsAlert } from '@/api';

function postFactoryAlert(alert) {
  return factoryEqpGroupsAlert.post(``, alert);
}

function putFactoryAlert(alertId, alert) {
  return factoryEqpGroupsAlert.put(`${alertId}`, alert);
}

function deleteFactoryAlert(alertId) {
  return factoryEqpGroupsAlert.delete(`${alertId}`);
}

function postFactoryAlertRsc(rsc) {
  return factoryEqpGroupsAlert.post(`rsc`, rsc);
}

function deleteFactoryAlertRsc(rscId) {
  return factoryEqpGroupsAlert.delete(`rsc/${rscId}`);
}

export { putFactoryAlert, deleteFactoryAlert, postFactoryAlert, postFactoryAlertRsc, deleteFactoryAlertRsc };
