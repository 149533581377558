import { factoryEquipments } from '@/api';

function getFactoryEqps(sortOrder, pageNum, pageSize, keyword) {
  return factoryEquipments.get(`?sortOrder=${sortOrder}&page=${pageNum}&pageSize=${pageSize}&keyword=${keyword}`);
}

function getFactoryEqpsWait() {
  return factoryEquipments.get(`waitingApproval`);
}

function getFactoryEqp(eqpId) {
  return factoryEquipments.get(`${eqpId}`);
}

function putFactoryEqp(eqpId, eqp) {
  return factoryEquipments.put(`${eqpId}`, eqp);
}

function deleteFactoryEqp(eqpGroupId) {
  return factoryEquipments.delete(`${eqpGroupId}`);
}

function getFactoryEqpsByGroup() {
  return factoryEquipments.get(`bygroup`);
}

function postFactoryEqpReject(eqpId) {
  return factoryEquipments.post(`reject/${eqpId}`);
}

function postFactoryEqpApprove(eqpId, eqp) {
  return factoryEquipments.post(`approve/${eqpId}`, eqp);
}

export {
  getFactoryEqps,
  getFactoryEqpsWait,
  getFactoryEqp,
  putFactoryEqp,
  deleteFactoryEqp,
  getFactoryEqpsByGroup,
  postFactoryEqpReject,
  postFactoryEqpApprove,
};
