import { factoryEqpGroups } from '@/api';

function getFactoryEqpGroups(sortOrder, keyword) {
  return factoryEqpGroups.get(`?sortOrder=${sortOrder}&keyword=${keyword}`);
}

function getFactoryEqpGroup(eqpGroupId) {
  return factoryEqpGroups.get(`${eqpGroupId}`);
}

function postFactoryEqpGroup(eqpGroup) {
  return factoryEqpGroups.post(``, eqpGroup);
}

function putFactoryEqpGroup(eqpGroupId, eqpGroup) {
  return factoryEqpGroups.put(`${eqpGroupId}`, eqpGroup);
}

function deleteFactoryEqpGroup(eqpGroupId) {
  return factoryEqpGroups.delete(`${eqpGroupId}`);
}

function postFactoryEqpGroupRsc(rsc) {
  return factoryEqpGroups.post(`rsc`, rsc);
}

function deleteFactoryEqpGroupRsc(rscId) {
  return factoryEqpGroups.delete(`rsc/${rscId}`);
}

function getFactoryEqpGroupsSelf() {
  return factoryEqpGroups.get(`self`);
}

export {
  getFactoryEqpGroups,
  getFactoryEqpGroup,
  postFactoryEqpGroup,
  putFactoryEqpGroup,
  deleteFactoryEqpGroup,
  postFactoryEqpGroupRsc,
  deleteFactoryEqpGroupRsc,
  getFactoryEqpGroupsSelf,
};
