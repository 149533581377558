var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _vm._m(0),
    _c("ul", { staticClass: "policy-list" }, [
      _c(
        "li",
        { staticClass: "mr-20" },
        [
          _c("router-link", { attrs: { to: "/privacy-policy" } }, [
            _vm._v("개인정보처리방침"),
          ]),
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/terms-of-use" } }, [
            _vm._v("서비스이용약관"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "maxst-logo mr-10",
        attrs: { src: "/images/maxst_logo.svg", alt: "MAXST logo" },
      }),
      _c("p", { staticClass: "copyright" }, [
        _vm._v("© 2021 MAXST Co., Ltd. All Rights Reserved."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }