import { postLearnQuality } from '@/api/modules/create/manualTargets';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async POST_LEARN_QUALITY(_, imgInfo) {
    const res = await postLearnQuality(imgInfo);
    return res.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
