import {
  getCheckHistoryItemsByEqpId,
  getCheckHistoryItemsExcel,
  getCheckHistoryItemsCalendar,
} from '@/api/modules/checkSheet/chkHistItems';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 특정 설비에 대해 특정 범위 일시의 점검 기록 요청
  async GET_CHECK_HISTORY_ITEMS_BY_EQP(
    { commit },
    [chkObjectId, startDT, endDT, keyword, filter, sortOrder, page, pageSize],
  ) {
    try {
      const { data } = await getCheckHistoryItemsByEqpId(
        chkObjectId,
        startDT,
        endDT,
        keyword,
        filter,
        sortOrder,
        page,
        pageSize,
      );
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 엑셀 다운로드
  async GET_CHECK_HISTORY_ITEMS_EXCEL(
    { commit },
    [chkObjectId, startDT, endDT, keyword, filter, sortOrder, equipmentName],
  ) {
    try {
      return await getCheckHistoryItemsExcel(chkObjectId, startDT, endDT, keyword, filter, sortOrder, equipmentName);
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // GET : 특정 설비의 상세 점검 현황 캘린더 조회
  async GET_CHECK_HISTORY_ITEMS_CALENDAR({ commit }, [chkObjectId, startDT, endDT]) {
    try {
      const { data } = await getCheckHistoryItemsCalendar(chkObjectId, startDT, endDT);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
