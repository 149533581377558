var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _vm.isShow
        ? _c(
            "general-simple-popup",
            [
              _vm.data.title
                ? _c("h2", {
                    attrs: { slot: "title" },
                    domProps: { innerHTML: _vm._s(_vm.data.title) },
                    slot: "title",
                  })
                : _vm._e(),
              _c("p", {
                attrs: { slot: "contents" },
                domProps: { innerHTML: _vm._s(_vm.data.description) },
                slot: "contents",
              }),
              _c(
                "button-basic",
                {
                  attrs: { slot: "button", color: "dark", size: "basic" },
                  on: { click: _vm.onClickButton },
                  slot: "button",
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.confirm_btn")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }