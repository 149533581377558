import i18n from '@/translation';
import LANGUAGE from '@/const/language';

const state = {
  errorPopupData: {
    title: '',
    description: '',
    isLogout: false,
  },
};

const getters = {};

const mutations = {
  SET_ERROR_POPUP(state, popupData) {
    switch (typeof popupData) {
      case 'string':
        state.errorPopupData = { title: '', description: i18n.messages[LANGUAGE].message[popupData], isLogout: false };
        break;
      case 'object':
        state.errorPopupData = {
          title: i18n.messages[LANGUAGE].message[popupData.title],
          description: i18n.messages[LANGUAGE].message[popupData.description],
          isLogout: popupData.isLogout || false,
        };
        break;
      default:
        break;
    }
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
