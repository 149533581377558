import {
  putManufacturerEqpGroup,
  getManufacturerEqpGroups,
  postManufacturerEqpGroup,
  getManufacturerEqpGroup,
  deleteManufacturerEqpGroup,
  deleteManufacturerEqpGroupRsc,
  postManufacturerEqpGroupRsc,
} from '@/api/modules/manufacturer/eqpGroups';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // GET : 설비 회사 설비 그룹 목록 조회
  async GET_MANUFACTURER_EQP_GROUPS({ commit }, [sortOrder, keyword]) {
    try {
      const { data } = await getManufacturerEqpGroups(sortOrder, keyword);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 설비 회사 설비 그룹 상세
  async GET_MANUFACTURER_EQP_GROUP({ commit }, eqpGroupId) {
    try {
      const { data } = await getManufacturerEqpGroup(eqpGroupId);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 설비 회사 설비 그룹 등록
  async POST_MANUFACTURER_EQP_GROUP({ commit }, eqpGroup) {
    try {
      const { data } = await postManufacturerEqpGroup(eqpGroup);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // PUT : 설비 회사 설비 그룹 수정
  async PUT_MANUFACTURER_EQP_GROUP({ commit }, [eqpGroupId, eqpGroup]) {
    try {
      const { data } = await putManufacturerEqpGroup(eqpGroupId, eqpGroup);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 설비 회사 설비 그룹 삭제
  async DELETE_MANUFACTURER_EQP_GROUP({ commit }, eqpGroupId) {
    try {
      const { data } = await deleteManufacturerEqpGroup(eqpGroupId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // POST : 설비 회사 설비 그룹 리소스 등록
  async POST_MANUFACTURER_EQP_GROUP_RSC({ commit }, rscObj) {
    try {
      const { data } = await postManufacturerEqpGroupRsc(rscObj);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },

  // DELETE : 설비 회사 설비 그룹 리소스 삭제
  async DELETE_MANUFACTURER_EQP_GROUP_RSC({ commit }, rscId) {
    try {
      const { data } = await deleteManufacturerEqpGroupRsc(rscId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
