<template>
  <nav :class="{ 'bg-gray60': grayHeaderPage.includes($route.name) }">
    <div class="logo-gnb-wrapper">
      <img
        src="/images/msf_header_logo.svg"
        alt="logo"
        class="cursor-pointer"
        role="button"
        @click="onClickHeaderLogo"
      />
      <ul v-if="hasPageMenuProfile">
        <li v-for="(gnb, index) in gnbList" :key="index">
          <button
            v-if="gnb.name === 'remote'"
            :class="{ 'active-gnb': $route.meta.gnb === gnb.name }"
            role="link"
            @click="onClickGnbRemote"
          >
            {{ gnb.title }}
          </button>
          <router-link :to="gnb.link" :class="{ 'active-gnb': $route.meta.gnb === gnb.name }" v-else>
            {{ gnb.title }}
          </router-link>
        </li>
      </ul>
    </div>
    <button-basic class="user-icon" circle="40" color="white" @click.stop="toggleUserMenu" v-if="hasPageMenuProfile">
      <span class="profile-image-wrapper" v-if="myInfo.rscUrl">
        <img :src="myInfo.rscUrl" class="profile-image" alt="Profile Image" />
      </span>
      <img src="/images/user_profile_none.svg" class="user-profile" alt="user-profile" v-else />
    </button-basic>
    <my-info
      v-show="isOpenUserMenu"
      :my-info="myInfo"
      :role="role"
      @click="toggleUserMenu"
      @togglePopup="togglePopup"
    />
    <profile-image-popup :is-show="showedPopupName === 'ProfileImagePopup'" @close="togglePopup" />
    <confirm-popup
      :is-show="showedPopupName === 'ConfirmPopup'"
      :contents-key="popupContentsKey"
      @close="togglePopup"
      @confirm="onConfirmInPopup"
    />
    <change-phone-popup :is-show="showedPopupName === 'ChangePhonePopup'" @close="togglePopup" />
    <change-password-popup :is-show="showedPopupName === 'ChangePasswordPopup'" @close="togglePopup" />
    <role-change-popup :is-show="showedPopupName === 'RoleChangePopup'" @close="togglePopup" />
  </nav>
</template>
<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import GnbData from './GnbData.json';
import ProfileImagePopup from '@/component/popup/profileImagePopup/ProfileImagePopup';
import ChangePhonePopup from '@/component/popup/changePhonePopup/ChangePhonePopup';
import ChangePasswordPopup from '@/component/popup/changePasswordPopup/ChangePasswordPopup';
import RoleChangePopup from '@/component/popup/roleChangePopup/RoleChangePopup';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import MyInfo from '@/component/header/myInfo/MyInfo';
import {
  NONE_MENU_PROFILE_PAGES,
  SIMPLE_PAGES,
  LOGO_TO_LOGIN_PAGES,
  GRAY_HEADER_PAGES,
} from '@/const/hasPageComponent';
import { REMOTE_URL } from '@/const/apiUrl';

export default {
  name: 'Header',
  data() {
    return {
      isOpenUserMenu: false,
      gnbList: [],
      showedPopupName: '',
      popupContentsKey: '',
      role: '',
      hasPageMenuProfile: false,
      logoToLoginPages: LOGO_TO_LOGIN_PAGES,
      grayHeaderPage: GRAY_HEADER_PAGES,
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapState('auth', ['rfToken']),
    ...mapGetters('users', ['isLogin', 'isFactoryComp', 'isSupervisor', 'isStaff', 'isLoginAndCurrentCompanyNone']),
  },
  created() {
    if (this.isLogin) {
      this.getMyInfo();
    }
    this.setGnbList();
    this.setHasPageMenuProfile();
    document.body.addEventListener('click', (e) => {
      e.stopPropagation();
      this.isOpenUserMenu = false;
    });
  },
  mounted() {
    this.onSetRole();
  },
  watch: {
    $route() {
      this.setGnbList();
      this.onSetRole();
      this.setHasPageMenuProfile();
    },
  },
  methods: {
    ...mapMutations('users', ['INIT_MY_INFO', 'INIT_MY_CURRENT_COMPANY']),
    ...mapActions('users', ['GET_MY_INFO']),
    async getMyInfo() {
      await this.GET_MY_INFO();
    },
    onClickGnbRemote() {
      this.$cookies.set('msf-sso-token', this.rfToken, null, null, 'msf.maxst.com');
      this.$cookies.set('current-company', this.currentCompany, null, null, 'msf.maxst.com');
      window.open(REMOTE_URL);
    },
    setHasPageMenuProfile() {
      if (NONE_MENU_PROFILE_PAGES.includes(this.$route.name)) {
        this.hasPageMenuProfile = false;
      } else {
        this.hasPageMenuProfile = !SIMPLE_PAGES.includes(this.$route.name) || this.currentCompany;
      }
    },
    onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'LOGOUT':
          this.$router.push('/').catch((err) => err);
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('currentCompany');
          sessionStorage.removeItem('myInfo');
          this.INIT_MY_INFO();
          this.INIT_MY_CURRENT_COMPANY();
          break;
        default:
          break;
      }
    },
    onSetRole() {
      switch (this.currentCompany) {
        case 'M':
          this.role = this.isSupervisor ? '슈퍼바이저' : '스태프';
          break;
        case 'F':
          this.role = '매니저';
          break;
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    toggleUserMenu() {
      this.isOpenUserMenu = !this.isOpenUserMenu;
    },
    setGnbList() {
      this.gnbList = GnbData.filter((gnb) => {
        switch (this.currentCompany) {
          case 'M':
            return this.isSupervisor ? gnb['isShowSupervisor'] : gnb['isShowStaff'];
          case 'F':
            return gnb['isShowManager'];
        }
      });
    },
    onClickHeaderLogo() {
      if (this.$route.name === 'RoleSelection') {
        this.$router.go(0);
      } else if (this.isLoginAndCurrentCompanyNone) {
        this.$router.push('/role-selection').catch((err) => err);
      } else if (!this.isLogin && this.logoToLoginPages.includes(this.$route.name)) {
        this.$router.push('/').catch((err) => err);
      } else {
        this.$router.push(this.gnbList[0].link).catch((err) => err);
      }
    },
  },
  components: {
    ConfirmPopup,
    ChangePhonePopup,
    ProfileImagePopup,
    ChangePasswordPopup,
    RoleChangePopup,
    MyInfo,
  },
};
</script>

<style scoped lang="scss">
@import 'Header';
</style>
