import { postActionRscFiles, deleteActionRscFiles } from '@/api/modules/checkSheet/actionRscFiles';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // POST : 조치 이미지 파일 생성
  async POST_ACTION_RSC_FILES({ commit }, rscFile) {
    try {
      const { data } = await postActionRscFiles(rscFile);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 조치 이미지 파일 삭제
  async DELETE_ACTION_RSC_FILES({ commit }, fileId) {
    try {
      const { data } = await deleteActionRscFiles(fileId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
