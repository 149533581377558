<template>
  <div id="app" :class="{ 'window-os': isWindowOS }">
    <header-component v-if="hasPageHeader" />
    <sidebar-component v-if="hasPageSidebar" />
    <router-view />
    <footer-component v-if="hasPageFooter" />
    <error-popup :is-show="isShowErrorPopup" :data="popupData" @close="onCloseErrorPopup(popupData)" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import HeaderComponent from '@/component/header/Header';
import SidebarComponent from '@/component/sidebar/Sidebar';
import FooterComponent from '@/component/footer/Footer';
import ErrorPopup from '@/component/popup/errorPopup/ErrorPopup';
import { SIMPLE_PAGES, NONE_SIDEBAR_PAGES, NONE_HEADER_PAGES } from '@/const/hasPageComponent';
import isNotChromeBrowser from '@/asset/js/isNotChromeBrowser';
import { MOBILE_ACCESSIBLE_PAGES } from '@/const/hasPageComponent';

export default {
  name: 'App',
  data() {
    return {
      errorCode: null,
      isShowErrorPopup: false,
      popupType: '',
      popupData: {},
      hasPageHeader: false,
      hasPageSidebar: false,
      hasPageFooter: false,
      role_change: {
        title: '',
        description: '계정의 권한이 변경되어 로그아웃 합니다. <br/>관리자에게 문의하세요.',
        isLogout: true,
      },
      forceLogout: '',
    };
  },
  computed: {
    ...mapState('globalError', ['errorPopupData']),
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapGetters('users', ['isLogin', 'isStaff', 'isMember']),
    isWindowOS() {
      return navigator.userAgent.toLowerCase().indexOf('windows') !== -1;
    },
  },
  created() {},
  mounted() {},
  watch: {
    $route() {
      this.setHasHeaderPage();
      this.setHasSidebarPage();
      this.setHasFooterPage();
      if (isNotChromeBrowser() && !MOBILE_ACCESSIBLE_PAGES.includes(this.$route.name)) {
        this.SET_ERROR_POPUP('recommend_chrome');
      }
      this.forceLogout = this.$route.query.forceLogout;
      if (this.forceLogout === 'true') {
        this.SET_NEXT_PAGE(this.$route.path);
        this.$router.push('/').catch((err) => err);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('myInfo');
        this.INIT_MY_INFO();
      }
    },
    myInfo(newVal, oldVal) {
      if (this.$route.name !== 'Login') {
        if (JSON.stringify(newVal.roleList) && JSON.stringify(newVal.roleList) !== JSON.stringify(oldVal.roleList)) {
          this.openGlobalErrorPopup(this.role_change);
        }
      }
    },
    errorPopupData(newVal) {
      this.openGlobalErrorPopup(newVal);
    },
  },
  methods: {
    ...mapMutations('users', ['INIT_MY_INFO', 'INIT_MY_CURRENT_COMPANY']),
    ...mapMutations('globalError', ['SET_ERROR_POPUP']),
    ...mapMutations('auth', ['SET_NEXT_PAGE']),
    setHasHeaderPage() {
      this.hasPageHeader = !NONE_HEADER_PAGES.includes(this.$route.name);
    },
    setHasSidebarPage() {
      this.hasPageSidebar = !NONE_SIDEBAR_PAGES.includes(this.$route.name);
    },
    setHasFooterPage() {
      this.hasPageFooter = SIMPLE_PAGES.includes(this.$route.name);
    },
    openGlobalErrorPopup(popupData) {
      this.isShowErrorPopup = true;
      this.popupData = popupData;
    },
    onCloseErrorPopup(popupData) {
      this.isShowErrorPopup = false;
      if (popupData.isLogout) {
        this.$router.push('/').catch((err) => err);
      }
    },
  },
  components: {
    ErrorPopup,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style lang="scss">
@import 'asset/style/App';
</style>
