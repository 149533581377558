import { getAdminConfigs } from '@/api/modules/common/adminConfigs';

const state = {
  profileIcons: JSON.parse(sessionStorage.getItem('profileIcons')) || [],
};

const getters = {};

const mutations = {
  SET_PROFILE_ICONS(state, iconsStr) {
    state.profileIcons = JSON.parse(iconsStr);
    sessionStorage.setItem('profileIcons', iconsStr);
  },
};

const actions = {
  async GET_ADMIN_CONFIGS({ commit }) {
    const res = (await getAdminConfigs()) || {
      data: {
        defaultProfileRes: { value: `[]` },
      },
    };
    const profileIconsStr = res.data.defaultProfileRes.value;
    commit('SET_PROFILE_ICONS', profileIconsStr);
    return res.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
