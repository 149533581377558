import {
  postChangeProfile,
  putChangeProfile,
  getResetPassword,
  putResetPassword,
  getMyInfo,
  putMyInfo,
  putChangePassword,
  getDuplicateCheck,
  getQrCode,
  getReissueQrCode,
} from '@/api/modules/common/users';
import getErrorMessage from '@/api/getErrorMessage';
import CompanyRole from '@/asset/data/CompanyRole.json';

const state = {
  myInfo: JSON.parse(sessionStorage.getItem('myInfo')) || {},
  currentCompany: sessionStorage.getItem('currentCompany') || '',
  isChangeProfile: false,
};

const getters = {
  isLogin(state) {
    return Object.keys(state.myInfo).length !== 0;
  },
  isManufacturerComp(state, getters) {
    return getters.isLogin && state.currentCompany === 'M';
  },
  isFactoryComp(state, getters) {
    return getters.isLogin && state.currentCompany === 'F';
  },
  isSupervisor(state, getters) {
    return getters.isLogin && state.myInfo['roleList'].includes('SUPERVISOR');
  },
  isStaff(state, getters) {
    return getters.isLogin && state.myInfo['roleList'].includes('STAFF');
  },
  isManager(state, getters) {
    return getters.isLogin && state.myInfo['roleList'].includes('MANAGER');
  },
  isMember(state, getters) {
    return getters.isLogin && state.myInfo['roleList'].includes('MEMBER');
  },
  isSupervisorOrFactory(state, getters) {
    return getters.isLogin && (getters.isSupervisor || getters.isFactoryComp);
  },
  hasMultiManagerRoles(state, getters) {
    return getters.isManager && state.myInfo['roleList'].length > 1;
  },
  isLoginAndCurrentCompanyNone(state, getters) {
    return getters.isLogin && !state.currentCompany;
  },
};

const mutations = {
  SET_MY_INFO(state, info) {
    state.myInfo = info;
    sessionStorage.setItem('myInfo', JSON.stringify(info));
  },
  INIT_MY_INFO(state) {
    state.myInfo = {};
  },
  SET_IS_CHANGE_PROFILE(state, payload) {
    state.isChangeProfile = payload;
  },
  SET_MY_CURRENT_COMPANY(state, roleList) {
    if (typeof roleList === 'string') {
      state.currentCompany = roleList;
    } else if (roleList.length === 1) {
      state.currentCompany = CompanyRole.manufacturer.includes(roleList[0]) ? 'M' : 'F';
    } else if (roleList.length === 2 && roleList.includes('MEMBER')) {
      state.currentCompany = 'M';
    } else {
      state.currentCompany = '';
    }
    sessionStorage.setItem('currentCompany', state.currentCompany);
  },
  INIT_MY_CURRENT_COMPANY(state) {
    state.currentCompany = '';
  },
};

const actions = {
  // PUT : 자신의 프로필 사진 수정
  async PUT_CHANGE_PROFILE({ commit }, imgObj) {
    try {
      const { data } = await putChangeProfile(imgObj);
      commit('SET_MY_INFO', { ...state.myInfo, ...data });
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // POST : 자신의 프로필 사진 등록
  async POST_CHANGE_PROFILE({ commit }, imgObj) {
    try {
      const { data } = await postChangeProfile(imgObj);
      commit('SET_MY_INFO', { ...state.myInfo, ...data });
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 비밀번호 재설정 이메일 요청, 메일을 보내든 안보내든 무조건 응답 200
  async GET_RESET_PASSWORD(_, loginId) {
    const { data } = await getResetPassword(loginId);
    return data;
  },
  // PUT : 비밀번호 재설정 요청
  async PUT_RESET_PASSWORD(_, pwData) {
    const { data } = await putResetPassword(pwData);
    return data;
  },
  // GET : 사용자 정보 조회
  async GET_MY_INFO({ commit }) {
    try {
      const { data } = await getMyInfo();
      commit('SET_MY_INFO', data);
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // PUT : 자신의 연락처 정보 수정
  async PUT_MY_INFO({ commit }, myInfo) {
    try {
      const { data } = await putMyInfo(myInfo);
      commit('SET_MY_INFO', { ...state.myInfo, ...data });
      return data;
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // PUT : 사용자 비밀번호 변경 요청
  async PUT_CHANGE_PASSWORD({ commit }, pwData) {
    try {
      const { data } = await putChangePassword(pwData);
      return data;
    } catch (error) {
      if (error.response.status !== 403) {
        const errorMessage = getErrorMessage('common', error.response.status);
        commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      }
      return Promise.reject(error);
    }
  },
  // GET : 아이디 중복 체크
  async GET_ID_DUPLICATE_CHECK(_, [loginId, type]) {
    const { data } = await getDuplicateCheck(loginId, type);
    return data;
  },
  // GET : 자신의 QR 코드 요청
  async GET_QR_CODE(_, value) {
    try {
      const { data } = await getQrCode(value);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // GET : 자신의 QR 코드 재발급 요청
  async GET_REISSUE_QR_CODE({ commit }, loginId) {
    try {
      const { data } = await getReissueQrCode(loginId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
