import { postCheckSheetItemFile, deleteCheckSheetItemFile } from '@/api/modules/checkSheet/checkSheetItemFiles';
import getErrorMessage from '@/api/getErrorMessage';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // POST : 점검 시트 관련 첨부 파일 저장 요청
  async POST_CHECK_SHEET_ITEM_FILE({ commit }, file) {
    try {
      const { data } = await postCheckSheetItemFile(file);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
  // DELETE : 점검 시트 관련 첨부 파일 삭제 요청
  async DELETE_CHECK_SHEET_ITEM_FILE({ commit }, fileId) {
    try {
      const { data } = await deleteCheckSheetItemFile(fileId);
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage('common', error.response.status);
      commit('globalError/SET_ERROR_POPUP', errorMessage, { root: true });
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
