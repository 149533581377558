import { manufacturerEquipments } from '@/api';

function getManufacturerEqps(pageNum, pageSize, sortOrder, keyword) {
  return manufacturerEquipments.get(`?page=${pageNum}&pageSize=${pageSize}&sortOrder=${sortOrder}&keyword=${keyword}`);
}

function getManufacturerEqp(eqpId) {
  return manufacturerEquipments.get(`${eqpId}`);
}

function postManufacturerEqp(eqp) {
  return manufacturerEquipments.post(``, eqp);
}

function putManufacturerEqp(eqpId, eqp) {
  return manufacturerEquipments.put(`${eqpId}`, eqp);
}

function deleteManufacturerEqp(eqpId) {
  return manufacturerEquipments.delete(`${eqpId}`);
}

function getManufacturerFactoryName() {
  return manufacturerEquipments.get(`factorys`);
}

export {
  getManufacturerEqp,
  putManufacturerEqp,
  deleteManufacturerEqp,
  getManufacturerEqps,
  postManufacturerEqp,
  getManufacturerFactoryName,
};
