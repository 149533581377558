var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { class: { "bg-gray60": _vm.grayHeaderPage.includes(_vm.$route.name) } },
    [
      _c("div", { staticClass: "logo-gnb-wrapper" }, [
        _c("img", {
          staticClass: "cursor-pointer",
          attrs: {
            src: "/images/msf_header_logo.svg",
            alt: "logo",
            role: "button",
          },
          on: { click: _vm.onClickHeaderLogo },
        }),
        _vm.hasPageMenuProfile
          ? _c(
              "ul",
              _vm._l(_vm.gnbList, function (gnb, index) {
                return _c(
                  "li",
                  { key: index },
                  [
                    gnb.name === "remote"
                      ? _c(
                          "button",
                          {
                            class: {
                              "active-gnb": _vm.$route.meta.gnb === gnb.name,
                            },
                            attrs: { role: "link" },
                            on: { click: _vm.onClickGnbRemote },
                          },
                          [_vm._v(" " + _vm._s(gnb.title) + " ")]
                        )
                      : _c(
                          "router-link",
                          {
                            class: {
                              "active-gnb": _vm.$route.meta.gnb === gnb.name,
                            },
                            attrs: { to: gnb.link },
                          },
                          [_vm._v(" " + _vm._s(gnb.title) + " ")]
                        ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm.hasPageMenuProfile
        ? _c(
            "button-basic",
            {
              staticClass: "user-icon",
              attrs: { circle: "40", color: "white" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleUserMenu.apply(null, arguments)
                },
              },
            },
            [
              _vm.myInfo.rscUrl
                ? _c("span", { staticClass: "profile-image-wrapper" }, [
                    _c("img", {
                      staticClass: "profile-image",
                      attrs: { src: _vm.myInfo.rscUrl, alt: "Profile Image" },
                    }),
                  ])
                : _c("img", {
                    staticClass: "user-profile",
                    attrs: {
                      src: "/images/user_profile_none.svg",
                      alt: "user-profile",
                    },
                  }),
            ]
          )
        : _vm._e(),
      _c("my-info", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpenUserMenu,
            expression: "isOpenUserMenu",
          },
        ],
        attrs: { "my-info": _vm.myInfo, role: _vm.role },
        on: { click: _vm.toggleUserMenu, togglePopup: _vm.togglePopup },
      }),
      _c("profile-image-popup", {
        attrs: { "is-show": _vm.showedPopupName === "ProfileImagePopup" },
        on: { close: _vm.togglePopup },
      }),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "ConfirmPopup",
          "contents-key": _vm.popupContentsKey,
        },
        on: { close: _vm.togglePopup, confirm: _vm.onConfirmInPopup },
      }),
      _c("change-phone-popup", {
        attrs: { "is-show": _vm.showedPopupName === "ChangePhonePopup" },
        on: { close: _vm.togglePopup },
      }),
      _c("change-password-popup", {
        attrs: { "is-show": _vm.showedPopupName === "ChangePasswordPopup" },
        on: { close: _vm.togglePopup },
      }),
      _c("role-change-popup", {
        attrs: { "is-show": _vm.showedPopupName === "RoleChangePopup" },
        on: { close: _vm.togglePopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }