var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "general-function-popup",
        { attrs: { "is-none-header-bg": true, "is-none-header-border": true } },
        [
          _c("h2", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("연락처 변경"),
          ]),
          _c(
            "div",
            { attrs: { slot: "contents" }, slot: "contents" },
            [
              _c("input-box", {
                staticClass: "mb-40",
                attrs: {
                  id: "phone",
                  label:
                    "연락처 (핸드폰 번호)<span class='color-primary400'>*</span>",
                  autocomplete: "off",
                  maxlength: "11",
                  defaultMessage: "숫자만 입력해 주세요",
                  "is-height-large": "",
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
            ],
            1
          ),
          _c(
            "button-basic",
            {
              attrs: { slot: "button", color: "gray-border-1" },
              on: { click: _vm.onClosePopup },
              slot: "button",
            },
            [_vm._v(" " + _vm._s(_vm.$t("message.cancel_btn")) + " ")]
          ),
          _c(
            "button-basic",
            {
              attrs: {
                slot: "button",
                color: "dark",
                disabled: !_vm.phone,
                "loading-dot": _vm.isLoadingButton,
              },
              on: { click: _vm.onChangePhone },
              slot: "button",
            },
            [_vm._v(" 변경 ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }